export default {
  // 全局
  app: {
    name: "Platform-based HR management",
    updates: "The new app has been released, please refresh the page!",
  },
  // 首页
  home: {
    welcome: {
      hello: "Hello",
      login: "Welcome",
    },
    shop: "EC Mall Premium Selection",
    member: "Standard Member",
    balance: "Balance",
    use: "Use",
    service: "Dedicated Customer Manager",
    records: "Record HR Growth",
    downloadUseBook: "Is it available to download the user guide?",
    useBook: "User Guide",
    card1: {
      title: "Background Check",
      btn: "Create",
      tips: "The settlement for background check services using {name} can be obtained by redeeming in the Resource Center",
      field2: "Account Statement",
      field3: "New Orders",
      field4: "Unread",
      field5: "Unmodified",
    },
    card2: {
      title: "Employee Benefits Platform",
      btn: "Management",
      tips: "{name} can be given to employees for redeeming welfare products, which can be obtained by redeeming in the Resource Center",
      field2: "Employee Count",
      field3: "Weekly Activity",
      field4: "Weekly Expenses",
      field5: "Total Expenses",
    },
    card3: {
      title: "Employee Incentive Platform - Smart Tips",
      btn: "Review",
      tips: "{name} can be given to employees for redeeming welfare products, which can be obtained by exchanging them in the Resource Center",
      field2: "Employee Count",
      field3: "Weekly Activity",
      field4: "Approved",
      field5: "Rewards Issued",
    },
    card4: {
      title: "Employee Engagement/Satisfaction Survey",
      btn: "Launching",
      tips: "{name} can purchase questionnaire topics and the number of employees for research, which can be obtained by exchanging them in the Resource Center",
      field2: "In Progress",
      field3: "Completed",
      field4: "Collection",
      field5: "Participants",
    },
    card5: {
      title: "Industry Salary",
      btn: "Customized",
      tips1: "Note: You can select up to",
      tips2: "items simultaneously",
      default: "System Default Indicators",
      link: "The above data is provided by SmartSalary. For more industry data and salary information for positions, please click",
      more: "Learn More",
      lastOne: "Please select at least one indicator!",
      auth: {
        title: "Authorization Reminder",
        note: "You are about to be redirected to the SmartSalary system and automatically logged in. Would you like to synchronize your account information?",
        required: "(Required)",
        company: "Company Name",
        email: "Email",
        name: "Name",
        phone: "Mobile Phone",
        confirm: "Agree & Use",
      },
    },
  },
  // 全局消息提醒
  showMsg: {
    success: {
      made: "Success",
      change: "Modification successful!",
      submit: "Submission successful!",
      save: "Save successful!",
      delete: "Deletion successful!",
      add: "Addition successful!",
      operation: "Operation successful!",
      audit: "Audit successful!",
      grant: "Grant successful!",
      copy: "Copy successful!",
    },
    confirm: {
      exitTitle: "Exit Confirmation",
      exit: "Are you sure you want to log out?",
      batchTitle: "Batch {msg}",
      batch: "Are you sure you want to perform a batch {msg} operation?",
    },
    warning: {
      unDev: "Function not yet available!",
      empty: "Data cannot be empty",
    },
    unAuthorized: "Not logged in",
    unPermission: "No permission to view the current page",
    helper1:
      "You can take a screenshot of this page and send it to your account manager to help you identify the cause of this upload failure.",
  },
  // 日期时间
  datetime: {
    today: "Today",
    yesterday: "Yesterday",
    week: "This week",
    lastWeek: "Last week",
    month: "This month",
    lastMonth: "Last month",
    quarter: "This quarter",
    lastQuarter: "Last quarter",
    year: "This year",
    lastYear: "Last year",
    unit: {
      month: "Month",
      monthFull: "Month",
      year: "Year",
      quarter: "Quarter",
      date: "Day",
      hour: "Hour",
      min: "Minute",
      second: "Second",
    },
  },
  // 货币
  currency: {
    price: "Yuan",
    growthPoints: "Growth Points",
  },
  // 文件上传下载
  file: {
    file: "File",
    format: "File format error!",
    formatLimit:
      "Only {format} format files are allowed, and the file size cannot exceed {size}",
    sizeMax: "File size cannot exceed {value}",
    upload: "Upload",
    downloadSelect: "Please select the file to download",
  },
  // 按钮
  button: {
    filter: "Search",
    reset: "Reset",
    resetPwd: "Reset Password",
    import: "Import",
    batchImport: "Batch Import",
    export: "Export",
    batchExport: "Batch Export",
    edit: "Edit",
    remove: "Delete",
    open: "Open",
    close: "Close",
    statusT: "Enable",
    statusR: "Disable",
    confirm: "Confirm",
    download: "Batch Download",
    downloadS: "Download",
    back: "Back",
    new: "Add",
    cancel: "Cancel",
    upload: "Reupload",
    previous: "Previous",
    next: "Next",
    newS: "Batch Add",
    dc: "Arrival",
    batchDel: "Batch Delete",
    candidate: "Candidate",
    check: "Quick Verify",
    detail: "View Details",
    detailWeb: "View Web Entry Details",
    order: "Place Order",
    add: "New",
    newAdd: "Add New",
    sexport: "Export",
    batchAdd: "Batch Add",
    uploadDragger: "Drag file here or click to upload",
    confirmDelete:
      "Are you sure to delete? Data cannot be recovered after deletion!",
    confirmReset:
      "Are you sure to reset? Data cannot be recovered after reset!",
    confirmCancel:
      "Are you sure to cancel? Data cannot be recovered after cancellation!",
    confirmAgree:
      "Are you sure to agree to {msg}? The operation cannot be undone after agreeing to {msg}!",
    confirmRefuse:
      "Are you sure to refuse {msg}? The operation cannot be undone after refusing {msg}!",
    audit: "Audit",
    batchDeleteSelected: "Please select at least one data item",
    yes: "Yes",
    no: "No",
    tips: "Tips",
    set: "Set",
    submit: "Submit",
    save: "Save",
    saveAndUp: "Save and Publish",
    permission: "Permission",
    disable: "Disable",
    enable: "Enable",
    offShelf: "Off Shelf",
    onShelf: "On Shelf",
    offShelfConfirm: "Are you sure to take off the shelf?",
    onShelfConfirm: "Are you sure to put on the shelf?",
    agree: "Agree",
    refuse: "Refuse",
    pass: "Pass",
    reject: "Reject",
    view: "View",
    done: "Done",
    saveAdd: "Save and continue creating",
    distribution: "Distribution",
    none: "None",
    addQuick: "Quick Add",
    addBatchQuick: "Batch Import Quick",
    delay: "Delay",
    previewBig: "Click to view larger image",
    continue: "Continue",
    copy: "Copy",
    confirmation: "Confirmation",
    modify: "Modify",
    reSend: "Resend",
    reload: "reload",
  },
  // 表格公用字段
  table: {
    createBy: "Created By",
    createTime: "Creation Time",
    updateBy: "Updated By",
    updateTime: "Update Time",
  },
  // 表单公用字段
  form: {
    auto: "Auto-generated",
  },
  // 账户账号
  user: {
    username: {
      label: "Mobile Number or ID",
      placeholder: "Enter your mobile number or Specific ID",
      validation: "Mobile number or Specific ID cannot be empty",
      mobile: "Please enter a valid mobile number or Specific ID",
    },
    password: {
      label: "Password",
      placeholder: "Enter login password",
      placeholderNew: "New Password",
      validation: "Password cannot be empty",
      strong: "Password must consist of numbers and letters",
      minLength: "Password must be at least 6 characters",
      maxLength: "Password cannot exceed {length} characters",
    },
    mobileCode: {
      label: "Verification Code",
      placeholder: "SMS verification code",
      validation: "Verification code cannot be empty",
      suffix: "Get Code",
      counterText: "s to resend",
      send: "SMS code sent",
      chineseMainlandOnly:
        "Verification code login only supports Chinese mainland mobile phone number",
    },
    resetPwd: {
      title: "Retrieve Password",
      tips: "Must be composed of more than 6 digits and letters, case sensitive",
      requiredLength: "More than {length} characters",
      requiredType: "requirements {type}",
      requiredTypeNumber: "number",
      requiredTypeLetter: "letters",
      requiredTypeSymbol: "symbol",
      caseSensitive: "case sensitive",
    },
    company: {
      label: "Company Name",
      placeholder: "Enter the company name",
      validation: "Company name cannot be empty",
    },
    name: {
      label: "Your Name",
      placeholder: "Enter your name",
      validation: "Your name cannot be empty",
    },
    remark: {
      label: "Your Requirements",
      placeholder: "Enter your requirements",
      validation: "Your requirements cannot be empty",
    },
    code: {
      label: "Verification Code",
      placeholder: "Enter verification code",
      validation: "Verification code cannot be empty",
    },
    title: "Welcome to",
    subTitle: "HR Management",
    signInTip: "Already have an account? ",
    signInSubTip: "Sign in now",
    signUpTip: "Don't have an account? ",
    signUpTip2: "No account? ",
    signUpSubTip: "Register a new account",
    signUpSubTip2: "Register now",
    signUpTitle: "Enterprise Registration",
    signUpTitleSub:
      "Please fill in the following information correctly. Registration will be successful after information review.",
    remember: "Remember me",
    forget: "Forget Password?",
    validation: "Please fill in the login information correctly!",
    submit: "Sign In",
    signUp: "Register",
    signUpDone:
      "We will contact you as soon as possible. Please pay attention to answering our customer service phone calls!",
    noCompany:
      "The current account has no login entity. Please contact the system administrator!",
    selectCompany: "Select the entity to log in",
    slideTip: "Hold and drag the slider to the rightmost position",
    slideSuccess: "Verification passed",
    slideWarning: "Please perform slider verification first!",
    verification: "Identity Verification",
    bindEmail: "Bind Email",
    bindEmailRequired: "Please select the email to bind",
    bindEmailTips:
      "Please select the email you need to bind. Due to the uniqueness of the mobile number,",
    bindEmailTips2:
      "other unselected emails need to be re-bound with a new mobile number before use",
    bindEmailConfirm1: "Are you sure you want to bind",
    bindEmailConfirm2: "with mobile number",
    bindEmailConfirm3: "?",
    reloadCodeImg: "Click to refresh",
    signOut: "Log out",
    backVersion: "Return to old version",
    tabs: {
      pwd: "Password login",
      sms: "SMS login",
    },
    unVerified:
      "Please verify your mobile phone number before proceeding with other operations",
    mobileVerify: "Mobile Phone Verify",
    emailLoginWarning: "Please use your mobile number or Specific ID to login",
    passwordExpired: "Password has expired",
    temporaryLock:
      "Too many login failures occur, please try again after {min} minute",
  },
  // 菜单
  menu: {
    empty:
      "The current user has not configured any menus. Please contact the administrator!",
    index: "Home",
    workspace: {
      index: "Dashboard",
      backCheck: "Background Check",
      toonPlat: "Welfare Platform",
    },
    hr: "HR Management",
    resource: {
      index: "Resource Center",
    },
    member: {
      index: "Member Center",
    },
    express: {
      index: "Delivery Management",
      express: "Express Companies",
      cost: "Express Fees",
    },
    sys: {
      index: "System Management",
      dic: "Data Dictionary",
      menu: "Menu Management",
      role: "Role Management",
      user: "User Management",
      org: "Organizational Structure",
      config: "System Configuration",
      company: "Company Management",
      exchange: "Enterprise Exchange Configuration",
      benefits: "System Benefits",
    },
    goods: {
      goods: "Goods Management",
      category: "Goods Categories",
      spec: "Goods Specifications",
      label: "Promotion Labels",
      list: "Goods List",
      banner: "Banner Management",
    },
    suppliers: {
      suppliers: "Supplier Management",
      suppliersList: "Supplier List",
      brand: "Brand List",
      coupon: "Coupon Management",
    },
    order: {
      index: "Commission Management",
      list: "Commission List",
      detail: "New Commission",
      report: "View Report",
    },
    orderManagement: {
      top: "Order Management",
      list: "Order Search",
      afterlist: "After-Sales Management",
    },
    echeck: {
      index: "EasyCheck Management",
      list: "EasyCheck List",
      detail: "New Quick Background Check",
    },
    bill: {
      index: "Statement List",
      list: "Statement List",
    },
    canddt: {
      index: "Candidate Management",
      list: "Candidate Management",
    },
    source: "Recruitment Company Source",
    bind: "Ecological Binding",
    system: "System Banner List Page",
    person: {
      index: "Personal Center",
      account: "Account Management",
      avatar: "Change Avatar",
      authority: "Permission Settings",
    },
    message: {
      index: "Messages",
      list: "Message List",
      report: "Report Progress Information",
    },
    present: {
      index: "Attendance Records",
      code: "Attendance QR Code",
      fb: "Fast Batch Verification",
      fs: "Fast Single Verification",
      tb: "Traditional Batch Verification",
      ts: "Traditional Single Verification",
      add: "New Attendance Information",
      info: "View Candidate Basic Information",
    },
    help: {
      index: "Help",
      download: "Download Management",
      tips: "Help Tips",
    },
    update: "System Update Records",
  },
  // 工作台-背景调查
  backCheck: {
    daochang: "New Attendance",
    order: "New Commission",
    tips: "Tips",
    progress: "Progress",
    qsbd: "Quick Background Check",
    hxrgl: "Candidate Management",
    addSource: "New Recruitment Source",
    source: "Recruitment Source",
    cname: "Candidate Name",
    cidcard: "Candidate ID Number",
    cphone: "Candidate Phone Number",
    hr: "Register HR",
    time: "Registration Time",
    status: "Authorization Status",
    dctitle: "Attendance Records",
    done: "Authorized",
    undo: "Unauthorized",
    authType: "Authorization Method",
    authOnline: "Online Collection",
    authOnlineTip1:
      "The system automatically initiates an authorization request to the candidate.",
    authOnlineTip2:
      "After successful authorization, verification work will be carried out.",
    authOffline: "Offline Authorization",
    authOfflineTip1:
      "Authorization has been obtained offline, no need to authorize again.",
    authOfflineTip2:
      "Please keep the offline authorization proof document, and the data source will be checked and summarized regularly.",
    overseasCandidateDataTip:
      "For overseas candidates without Mainland ID, please place your order by emailing ({email})  or contacting our customer service.",
    overseasCandidateDataTipHrefTitle: "Click for details",
    overseasCandidateDataEmailSubject:
      "Order%20For%20overseas%20candidates%20without%20Mainland%20ID",
    stepStatus: "Stage Status",
    signType: "Report Level",
    oneitem: "Select at least {num} items",
    moreitem: "Select up to {num} items",
    authModal: {
      title:
        "Some candidates in your selected records have not been authorized",
      tip: "The system will automatically collect authorization from candidates. After the candidate's successful authorization, the 'Status' field in the system will automatically change to 'Authorized'.",
    },
    backType: "Background Check Type",
    noAuth:
      "The candidate has not authorized, and the authorization letter cannot be downloaded at the moment.",
    noPic: "No Photo Available",
    siteInfo: "On-site Information",
    noSign: "No Signature Available",
    dcTitle: "Enter Attendance Information",
    dcAddTitle: "Edit Candidate Information",
    dcHasAddTitle: "Candidates Added",
    dcNotAddTitle: "Candidates Not Added",
    registrationSource: "Record Source",
    orPackageSelect: "Template Selection",
    alertTitle: "Friendly Reminder",
    alertDesc1:
      "In-depth verification module content (the number at the end represents the number of resume sections).",
    alertDesc2: "If you want to configure more modules, please contact us!",
    hname: "Candidate Name",
    hphone: "Mobile Phone Number",
    hphoneShort: "Mobile Phone",
    hstatus: "Status",
    hprocess: "Progress",
    hflowup: "Follow-up",
    hconcat: "Candidate Phone Number",
    hicard: "Candidate ID Number",
    hpstatus: "Stage Status",
    sms: "SMS Verification",
    smscode: "Send Verification Code",
    hrCheck: "Please HR verify the identity",
    resetsms: "Resend SMS Code",
    smsuccess: "SMS verification code sent successfully",
    smsExpired: "valid until {min}!",
    smsInvalid:
      "The sms code is wrong or has expired. Please resend the sms code or try again later!",
    sendText: "Resendable in {second}s",
    exporting: "Exporting in progress",
    downFile: "Download Exported File",
    templateWorkOrder: "Employee Information Import Template.xlsx",
    report: {
      client: "Client",
      submitDate: "Report Submission Date",
      idCard: "ID Number",
      show: "Show",
      noShow: "Do Not Show",
      problemName: "Candidate Provided Information",
      summaryValue: "Detailed Explanation",
      authenticity: "Authenticity",
      empty: "No relevant information found",
    },
    hprt: "Stage Report Time",
    hcs: "Task Status",
    hc: "All Candidates",
    hecheckr: "EasyCheck Records",
    echeckName: "EasyCheck",
    horderr: "Commission Records",
    hqt: "Registration Time",
    hbatchdc: "Batch Import Attendance",
    hbatchbd: "Batch Background Check",
    hbatchorder: "Batch Commission",
    hauthselectdata: "Selected Data",
    hnoauth: "Includes candidates who are not authorized",
    hauthtip:
      "The system will automatically collect authorization from candidates. After the candidate's successful authorization, the 'Status' field in the system will automatically change to 'Authorized'.",
    hauthtype: "Add Type",
    hcname: "Copy to",
    completeReportTime: "Final Report Time",
    template: "Template",
    module: "Module",
    indexECharts: {
      collectOver: "Info Collected",
      collecting: "Info Collecting",
      conductSum: "In Progress",
      overSum: "Completed",
      revokeSum: "Revoked",
      stopSum: "Stopped",
      total: "Total",
    },
    interview: {
      detail: {
        title: "Web Entry Attendance Information",
        subTitle: "Candidate Basic Information",
      },
    },
    merged: {
      tabTitle: "Background Check Record",
      packageName: "Report version",
      consignTime: "Commission time",
      guidance: "ECheck Records and Commission Records Merged",
      guidanceOk: "Got it",
    },
    // backCheck.qrCodeAuthList 二维码授权列表
    qrCodeAuthList: {
      title: "QR Code Authorization List",
      downloadBook: "download authorization form",
      authTime: "authorization time",
      selectedTotal: "{count} form has been selected",
      unSelectedMsg:
        "Please check the authorization form and click here to create it in batches",
    },
  },
  // 工作台-福利平台
  welfarePlat: {
    index: {
      memberCount: "Employee Count",
      weekActive: "Weekly Activity",
      unlogin: "Not Logged In",
      logined: "Logged In",
      active: "Activated",
      unActive: "Not Activated",
      accountRecord: "Account Records",
      baseSet: {
        title: "Platform Basic Settings",
        name: "Welfare Platform Name",
        nameRequired: "Name cannot be empty",
        nameMax: "Name cannot exceed {length} characters",
        logo: "Platform Logo",
        logoTips: "Recommended size: Width 468px, Height 204px",
        logoRequired: "Logo cannot be empty",
        moneyName: "Platform Currency Name",
        moneyNameTips: "Up to {length} Chinese characters",
        moneyNameRequired: "Currency name cannot be empty",
        moneyNameMax: "Currency name cannot exceed {length} characters",
        tool: "Use Incentive Tool",
        toolTips:
          "When enabled, employees can use incentive tools on the platform",
      },
      allocationPoints: {
        title: "Redeem {name}",
        canAllocation: "Can Allocation",
        consumptionWeek: "Consumption This Week",
        consumptionRecords: "Consumption Details",
        consumptionTotal: "Total Employee Consumption",
        consumptionTitle: "Employee Consumption Details",
        consumptionCol1: "Product",
        consumptionCol2: "Employee Name",
        consumptionCol3: "Consumption {name}",
        consumptionCol4: "Order Status",
        consumptionCol5: "Time",
        consumptionCol6: "Service Fee",
        allocationHandle: "Manual Allocation",
        allocationAuto: "Automatic Allocation",
      },
      accountRecords: {
        title: "Account Records",
        col1: "Time",
        col2: "Type",
        col3: "Object",
        col4: "Amount",
        col5: "Account Balance",
        col6: "Service Fee",
      },
    },
    manualRelease: {
      title: "Manual Release",
      single: "Single Person Release",
      batch: "Batch Release",
      form: {
        canUse: "Available",
        userIds: "Employee Name",
        userIdsPlaceholder: "Select Employee Name",
        userIdsRequired: "Employee Name cannot be empty",
        mobile: "Phone Number",
        mobilePlaceholder: "Enter Phone Number",
        mobileRequired: "Phone Number cannot be empty",
        mobileReg: "Invalid phone number format",
        email: "Employee Email",
        emailPlaceholder: "Enter Employee Email",
        emailRequired: "Employee Email cannot be empty",
        emailReg: "Invalid email format",
        points: "Quantity to Send",
        pointsPlaceholder: "Enter the quantity of welfare for this release",
        pointsRequired: "Quantity cannot be empty",
        pointsRequired2: "Amount to send cannot be empty",
        pointsReg: "Amount to send must be a valid number",
        benefitsId: "Corporate Welfare",
        benefitsIdPlaceholder: "Select Corporate Welfare",
        benefitsIdRequired: "Corporate Welfare cannot be empty",
        payPassword: "Payment Password",
        payPasswordPlaceholder: "Enter Payment Password",
        payPasswordRequired: "Payment Password cannot be empty",
        currency: "Currency",
        remark: "Remark",
        remarkPlaceholder: "Enter remark",
        filePlaceholder: "Upload import file",
        fileTips:
          "Select corporate welfare and upload the file after entering the payment password",
      },
      batchTips:
        "Download the template first, fill it out, and then upload it.",
      batchDownload: "Download",
      batchUpload: "Upload",
      editType: "Edit Corporate Welfare",
      batchFileName: "Batch Release File",
      readExcelFailed: "File parsing failed, technical information: {msg}.",
      readExcelMessage: "Row {index}: {msg}.",
      readExcelConfirm:
        "Confirm that the amount to send is correct and send to the following employees?",
      readExcelConfirmOk: "Confirm Send",
    },
    companyBenefits: {
      title: "Corporate Welfare",
      formTitle: "Edit {name}",
      namePlaceholder: "Enter welfare name",
      name: {
        label: "Welfare Name",
        placeholder: "Enter welfare name",
        required: "Welfare name cannot be empty",
        max: "Welfare name cannot exceed {length} characters",
      },
      benefitsType: {
        label: "Welfare Type",
        placeholder: "Select welfare type",
        required: "Welfare type cannot be empty",
      },
      companyId: {
        label: "Company Name",
        placeholder: "Select company",
        required: "Company name cannot be empty",
      },
      grantObject: {
        label: "Grant Object",
        placeholder: "Select grant object",
        required: "Grant object cannot be empty",
      },
      userIds: {
        label: "Grant Employees",
        placeholder: "Select grant employees",
        required: "Grant employees cannot be empty",
      },
      grantTime: {
        label: "Grant Time",
        placeholder: "Select grant time",
        required: "Grant time cannot be empty",
        birthday: "Grant on the employee's solar birthday",
      },
      num: {
        label: "Quantity",
        placeholder: "Enter quantity",
        required: "Quantity cannot be empty",
      },
      state: {
        label: "State",
      },
      isAuto: {
        label: "Auto Send",
      },
      isSysBenefits: {
        label: "System Welfare",
      },
      remark: {
        label: "Remark",
        placeholder: "Enter welfare remark",
        max: "Remark cannot exceed {length} characters",
      },
      employment: {
        label: "Seniority",
        placeholder: "Add seniority",
        required: "Seniority cannot be empty",
        year: "Grant Seniority ({unit})",
        yearRequired: "Grant seniority cannot be empty",
        yearUniq: "Seniority duplicates between row {sn1} and row {sn2}!",
        points: "Grant Quantity ({name})",
        pointsRequired: "Grant quantity cannot be empty",
        btn: "Edit Seniority",
      },
    },
    autoManualRelease: {
      title: "Automatic Release",
      add: "Add Release Plan",
      list: {
        col1: "Welfare Type",
        col2: "Welfare Name",
        col3: "Release Amount ({name})",
        col4: "Number of Received",
        col5: "Creation Time",
        col6: "Enable or Not",
      },
    },
  },
  // 首页
  index: {
    grxx: {
      title: "Personal Information",
      hello: "Good morning,",
      welcome: "Welcome to EasyCheck Background Check",
      service: "My Exclusive Customer Service Manager",
      customer: "Customer Service Department",
      email: "Email",
      contact: "Contact Information",
    },
    ksxj: {
      title: "Quick Create",
      daochang: "Create New Arrival",
      order: "Create New Commission",
      qsbd: "Quick Background Check",
    },
    sjjb: {
      title: "Data Briefing",
      dzd: "Pending Reconciliation Statements",
      wdbg: "Unread Reports",
      wtd: "Pending Modification Commission",
      wtdl: "New Commission Quantity",
    },
    xjjl: "Message Records",
    noMsg: "No Message Records",
    wtdl: "Commission Quantity (pieces)",
    dpdj: "Shield Level Distribution",
    wtdlqs: "Commission Quantity Trend for the Year {year}",
    jdbgsl: "Number of Stage Reports in the Last 30 Days (pieces)",
    fxfx: "Risk Analysis of Candidates Recruitment",
    gzsl: "Number of Commission Tracking in the Last 30 Days (pieces)",
    more: "View All",
    unit: "pieces",
  },
  // 委托
  order: {
    title: {
      query: "Quick Search",
      list: "Commission List",
      name: "Commission",
    },
    filter: {
      name: "Candidate Name",
      number: "Report Number",
      hr: "Commission HR",
      status: "Report Status",
      level: "Report Level",
      time: "Registration Time",
      confirm: "Info Confirm Time",
      report: "Report Reception Time",
      process: "Task Progress",
    },
    table: {
      update: "Change Status",
      data: "Data Management",
      detail: "Report Details",
      stop: "Stop Verification",
    },
    dataForm: {
      title: "Candidate Data Management",
      filename: "File Name",
      type: "Type",
      updateTime: "Last Modified Time",
    },
    stopForm: {
      desc: "Change Description",
      descRequired: "Please enter change description",
    },
    button: {
      add: "Create New Commission",
    },
    report: {
      info: "Basic Information",
      view: "Overview",
      table: {
        name: "Module Name",
        status: "Status",
        show: "Display Status",
        desc: "Concept Description",
        dp: "Shield",
      },
    },
    detail: {
      info: {
        custom: "Customer Name",
        area: "Region",
        mobile: "Commission HR Mobile",
        address: "Customer Email",
        telephone: "Commission HR Landline",
        cc: "CC Email",
        addcc: "Add CC Email",
        confirm: "Are you sure you want to delete the email address?",
      },
      name: "Candidate Name",
      sex: "Candidate Gender",
      phone: "Candidate Phone Number",
      phoneCode: "Candidate Phone Number Area Code",
      urgent: "Urgent",
      lang: "Report Language",
      langEmail: "Email Language",
      process: "Report Progress Tracking",
      entry: "Expected Entry Date",
      email: "Candidate Email",
      agency: "Position Institution",
      address: "Workplace",
      note: "Note",
      concat: "Contact Candidate",
      profile: "Attachment Resources",
      upload: "Upload Attachment",
      uploadFailed: "Upload Failed",
      uploadFailedMsg: "File [{name}] upload failed:",
      uploadFailed400: "Please check if your network connection is normal!",
      uploadFailed300:
        "Upload request was redirected! Please contact the network management department to see if uploading is allowed!",
      uploadFailedOther:
        "Upload request failed, technical information: {msg}, please contact customer service or try again later!",
      uploadFailedHelp:
        "If you need help, please screenshot this prompt and error code, then contact your customer manager.",
      uploadFailedTid: "Error Code: ",
      uploadFailedAcceptError: "File format error",
      uploadFailedAccept: "Currently supported formats are:",
      uploadFailedLimitError: "File too large",
      uploadFailedLimit: "File size cannot exceed {limit}",
      uploadHelper: {
        modalTitle: "Candidate File Upload Failed",
        confirm: "File upload failed, please open the file upload assistant",
        confirmCancel: "Cancel",
        confirmOk: "File Upload Assistant",
        confirmTips:
          "If you have failed to upload multiple times, you can open the file upload assistant and use another method to send the file as prompted. After clicking 'Cancel,' close the popup and continue the order.",
        confirmCloseTips:
          "Once this popup is closed, it cannot be opened again. Please make sure you have resolved the issue of uploading files.",
        title: "Introduction to This Page",
        email: "Send files by email",
        emailContent1:
          "This content format is automatically recognized by the system and cannot be edited.",
        emailContent2: "After copying, paste it directly into the email body.",
        emailContent3:
          "Select all the attachments about the candidate that you need to upload and submit them via email.",
        emailContent4: "File size: less than 50M",
        emailContent5: "Supported formats are as follows:",
        fallback: "Problem Feedback",
        way: "Method {no}:",
        tips: "Please follow the steps below to upload files.",
        pe11: "For users with email clients installed on their computers.",
        pe12: "①Click the button below to open the email client.",
        pe13: "②Select the attachments you need to upload to the email and confirm that the email is sent successfully.",
        pe14: "Send in One Click",
        pe21: "For users without email clients installed on their computers.",
        pe22: "①Log in to your email and open the email writing page.",
        pe23: "②Please click to check the information.",
        pe24: "Check Information",
        pe25: "After checking the information, the email content in ③ will be displayed.",
        pe26: "③Click Copy to copy all the recipient, subject, and body information below.",
        pe27: "Recipient:",
        pe28: "Subject:",
        pe29: "Body:",
        pe210:
          "④Select the attachments you need to upload to the email and confirm that the email is sent successfully.",
        pe211:
          "⑤Continue to fill in the background information. After you submit the background information, the system will automatically associate the files attached to your email with the candidate you commissioned for the background check.",
        fallbackTips:
          "You can screenshot the bottom of this page and send it to your customer manager to help you troubleshoot the reason for this upload failure.",
        demoTips: "Click to view examples",
        demoImgAlt: "Email Style Example",
        validBeforeEdit: "Please click the confirm modification button first!",
        callEmailClient: "Email call-up successful!",
      },
      source: "Candidate Recruitment Source",
      addSource: "New Recruitment Source",
      addCan: "Add Candidate",
      costCenter: "Cost Center",
      reportLang: "Report Language",
      isCanConcat: "Can Contact",
      isDelete: "Are you sure you want to remove the candidate?",
      zh: "Chinese",
      en: "English",
      empty: "Please add candidates",
      emptyTemplate: "Please select a template",
      emptySource: "Please select a recruitment source",
      templateTips1:
        "Deep verification module content (the numbers behind represent the number of resume sections)",
      templateTips2:
        "If you want to configure more modules, please contact us!",
      templateTips3:
        'When modifying the number of "experience" or "performance" sections, make sure that the number of "experience" sections is greater than or equal to the number of "performance" sections.',
      invalid: "The report does not exist!",
      expandMore: "Expand More",
      shrinkMore: "Shrink",
      joinGift: "Joining Gift",
      joinGiftTips:
        "After the final report is passed for 24 hours, the candidate will receive the joining gift via email to provide the shipping address.",
      downloadBatchTemplate: "Download Candidate Template Spreadsheet",
      downloadBatchTemplateTips:
        "(Download the latest template before importing. The header names in the template cannot be changed, and the header row cannot be deleted.)",
      downloadBatchTemplateTitle: "Upload Spreadsheet",
      downloadBatchTemplateTips2:
        "The file extension must be xls or xlsx (i.e., Excel format), and the file size must not exceed 10M. Up to 3000 data entries can be imported.",
      downloadBatchTemplateTips3:
        "The required contents for the selected query items are as follows:",
      downloadBatchTemplateTips4:
        "Note: To ensure successful upload, make sure the required content is complete.",
      batchClear: "Clear All Data",
      batchClearConfirm:
        "Are you sure you want to clear all data? Data cannot be recovered after clearing!",
      batchDesc: "Description",
      batchDescP1:
        "You can edit candidate information in the template and upload the file with one click.",
      batchDescP2:
        "The data shown below is for data preview only and cannot be changed.",
      batchDescP3:
        "When importing data, upload all the candidate information at once. Multiple import operations are not supported. After the re-import, the data will overwrite the uploaded candidate information.",
      success: "Operation successful",
      successAutoJump:
        "Automatically jump to the candidate management page in {s} seconds",
      selectedTemplate: "Selected Template",
      downloadQRCode: "Download QR Code",
    },
    gift: {
      name: "Joining Gift",
      title: "Employee Joining Gift",
      grantRecord: "Joining Gift Grant Record",
      changeRecord: "Joining Gift Change Record",
      unit: "pieces",
      description: {
        t1: "1. The system will distribute joining gifts through the Yidian Care Welfare Platform.",
        t1s1: "(1) For unregistered employees, the order information of the candidate will be used to register the welfare platform account.",
        t1s2: "(2) For accounts that are already registered but not associated with the client's company, an association operation will be performed.",
        t2: "2. When the status of the joining gift order is not started, it can be canceled at any time; other statuses cannot be canceled.",
        t3: "3. The system will send an email to the selected recipients to obtain the employee's shipping information and inform the employee of the progress of the joining gift.",
        t4: "4. If the employee fails to submit information via email within 7 days, the joining gift will be automatically canceled.",
        preT: "Pre-booked Joining Gift (the following rules are only valid when the joining gift is selected when placing an order):",
        pre1: "1. The joining gift selected when creating a new commission is only valid when the report result is Green Shield or Blue Shield; other report levels will be automatically canceled.",
        pre2: "2. If the order is canceled midway and the final report is not issued, the pre-booked joining gift will be canceled.",
        pre3: "3. The system will enter the joining gift distribution process 24 hours after the final report is issued.",
      },
      grant: "Grant Joining Gift",
      selected: "Selected",
      number: "Available Quantity",
      list: {
        expNum: "Express Number",
        createTime: "Operation Order Time",
        name: "Joining Gift Package",
        reason: "Cancellation Reason",
        cancel: "Cancel Joining Gift",
        balanceLabel: "Remaining Total Package",
      },
      record: {
        value: "Transaction",
        last: "Remaining Quantity",
      },
    },
  },
  // 易查宝列表
  echeck: {
    title: "Candidate List",
    filter: {
      time: "Query Time",
    },
    add: "Batch Traditional Commission",
    table: {
      idCard: "ID Card Number",
      level: "Position Level",
      checkTime: "Verification Time",
      mobile: "Candidate Phone",
      add: "Add Verification Module",
      authorizationRequired: "Authorization Required",
    },
    detail: {
      package: "Yichabao Package Selection",
      fsTitle: "Candidate Fast Verification",
      create: "Create Fast Background Check",
      level: "Candidate Level",
      levelMax: "Candidate level cannot exceed {length} characters",
      must: "Must Choose",
      last: "Remaining",
      times: "Times",
      template: "Employee Information Import Template - Yichabao Order.xlsx",
      emptyModule: "Yichabao package cannot be empty",
      emptyCandidate: "Candidate cannot be empty",
      unsavedCandidate: "There are unsaved candidates",
      authorizationRequired: "Authorization Required",
      candidateInfoImportFailedTitle:
        "Some information about the candidate uploaded is incorrect",
      candidateInfoImportFailedType: "Line {line} [{name}]: {msg}",
      candidateInfoImportResultTitle: "Batch import candidates",
      candidateInfoImportSuccessMessage:
        "{count} items of information have been imported. Check them at the bottom of the page",
      candidateInfoImportEmptyMessage: "No valid information found",
    },
  },
  // 对账单列表
  bill: {
    filter: {
      org: "Organization",
      code: "Payment Code",
      time: "Report Receipt Time",
      status: "Accounting Status",
    },
  },
  // 招聘来源
  source: {
    tip: "Show Closed Channels",
    add: "Add Recruitment Source",
  },
  // 个人中心
  person: {
    account: {
      title: "Account Security",
      old: "Old Password",
      new: "New Password",
      confirm: "Confirm Password",
      edit: "Confirm Edit",
      bindTitle: "Account Binding",
      mobile: "Mobile Number",
      check: "Verified",
      changeMobile: "Change Mobile Number",
      editMobile: "Edited Mobile Number",
      code: "Verification Code",
    },
    avatar: {
      title: "Upload Avatar",
      preview: "Avatar Preview",
      submit: "Submit Avatar",
      select: "Please select your avatar",
    },
    permission: {
      filter: {
        title: "Current Role",
        depart: "Department Name",
        status: "Status",
      },
      account: {
        title: "All Accounts",
        add: "Add Subaccount",
        had: "Selected",
        select: "Records",
        table: {
          name: "Nickname (System Name)",
          mobile: "Phone",
          depart: "Main Department",
          second: "Subsidiary Department",
          status: "Status",
        },
      },
      set: {},
    },
  },
  // 消息记录
  message: {
    content: "Message Content",
    time: "Time",
    report: {
      process: {
        title: "Task Progress",
        s1: "Client Commission",
        s2: "Data Collection",
        s3: "Data Review",
        s4: "Report Generation",
        s5: "Report Review",
        s6: "Phase Report",
        s7: "Final Report",
        s8: "Accounting Complete",
        s9: "Candidate Identity Verification Failed",
        tip1: "Blue icons with time: This process has been completed",
        tip2: "Gray icons: This process is not yet completed",
      },
      info: {
        order: "Order Information",
        number: "Order Number",
        person: "Order Creator",
        version: "Report Version",
      },
      canInfo: "Candidate Information",
      order: "Verification Package",
    },
  },
  // 到场记录
  present: {
    code: "Check-in QR Code",
    filter: {
      type: "Check-in Record Category",
      sblr: "Device Entry",
      weblr: "Web Entry",
    },
    list: {
      check: "Quick Verification",
      detail: "View Details",
      order: "Create Commission",
      hr: "Register HR",
      time: "Registration Time",
      add: "Add Check-in",
      checkT: "Verification Method",
    },
    verify: {
      fsList: "Candidate Quick Verification List",
      cphone: "Phone Number",
    },
    add: {
      title: "Add Candidate Information",
    },
    info: {
      can: "Candidate Basic Information",
      card: "ID Card Basic Information",
      tra: "Traditional Background Check",
      hometown: "Hometown",
      birth: "Date of Birth",
      nation: "Ethnicity",
      max: "ID Card Expiry Date",
      faceRecognition: "Face Recognition",
      signPicture: "Authorized Signature Display",
      authFile: "Authorization Form Download",
      template: "Check-in Record Import Template.xlsx",
    },
  },
  // 系统更新记录
  update: {
    content: "Update Content",
    time: "Update Time",
    view: "View Specific Update Features",
  },
  period: ".",
  comma: ",",
  space: " to ",
  filter: "Quick Search",
  action: "Action",
  selectAll: "Select All",
  input: "Please enter",
  right: "Correct",
  select: "Please select",
  tiao: " items",
  item: " data",
  selected: "Selected",
  total: "Total",
  noData: "No data available",
  tinymcePlaceholder: "Enter text here",
  maxInput: "{label} cannot exceed {length} characters",
  section: " section(s)",
  leastOne: "At least one",
  // modal
  homeModal: {
    address: {
      title: "Custom Email Address",
      member: "All Members",
      cc: "CC Email",
      ccUniq: "Email already exists",
    },
    source: {
      remark: "Quickly add new on-site candidate recruitment sources",
      dictCode: "Source Code",
      dictCodePlaceholder: "Enter source code",
      dictCodeRequired: "Source code cannot be empty",
      dictCodeMax: "Source code cannot exceed {length} characters",
      dictName: "Source Name",
      dictNamePlaceholder: "Enter source name",
      dictNameRequired: "Source name cannot be empty",
      dictNameMax: "Source name cannot exceed {length} characters",
      list: {
        web: "Recruitment Website",
        meet: "Job Fair",
        forum: "Forum",
      },
    },
    import: {
      step1: {
        title: "Upload File",
        tips: "Please upload the file",
        limitSize: "File size cannot exceed {size}",
        download: {
          title: "Fill in Import Data Information",
          desc: "Download the latest template before importing. The template's header names cannot be changed, and the header row cannot be deleted.",
          action: "Download Template",
        },
        upload: {
          title: "Upload Completed Information Table",
          desc: "File extension must be xls or xlsx (Excel format). File size cannot exceed 10M. Supports importing up to 3000 data entries.",
          action: "Upload File",
          message: {
            title: "Special Notice",
            content:
              "If a customer with the same name is found during import and you have update permissions for that customer's data, the data will be updated.",
          },
        },
        success: "Normal quantity",
        fail: "Abnormal quantity",
        tip: "Abnormal prompt",
      },
      step2: {
        title: "Data Preview",
      },
      step3: {
        title: "Import Data",
        success: "Batch import completed",
        successCount: "You have successfully imported",
        importing: "Importing data",
      },
    },
    account: {
      info: "Account Information",
      pinfo: "Personal Information",
      birthday: "Birthday",
      email: "Email",
      id: "ID Card Number",
      wechat: "WeChat",
      phone: "Phone",
      amail: "Account Email",
      realname: "Real Name",
      mobile: "Mobile Number",
      init: "Initial Password",
      new: "New Password",
      sex: "Gender",
    },
    role: {
      index: "Role",
      title: "Edit Role",
      type: "Type",
      name: "Name",
      desc: "Description",
      create: "Creator",
      createT: "Creation Time",
    },
    isList: {
      is: "Yes",
      not: "No",
    },
    expeditedList: {
      urgent: "Urgent",
      generally: "Generally",
    },
    sexList: {
      female: "Female",
      male: "Male",
      unknown: "Unknown",
    },
    progressList: {
      no: "Not Followed",
      very: "Moderately Followed",
      all: "Highly Followed",
      general: "Fully Followed",
    },
    statusList: {
      all: "All",
      have: "Read",
      unread: "Unread",
    },
  },
  // 人事管理
  hrManagement: {
    staffList: {
      tab: "Employee Management",
      addBtn: "Create Employee",
      editBtn: "Details",
      removeBtn: "Resign",
      table: {
        col1: "Name",
        col2: "Mobile Number",
        col3: "Email",
        col4: "Department",
        col5: "Status",
        col6: "Remaining {name}",
        col7: "Login Times",
        col8: "Last Login Time",
        col9: "Enabled",
        col10: "Action",
        col11: "User Type",
      },
      form: {
        name: {
          label: "Name",
          validation: {
            required: "Please enter the employee's name",
            max: "Employee name cannot exceed 100 characters",
          },
          placeholder: "Enter the employee's name",
        },
        userType: {
          label: "User Type",
          placeholder: "Select user type",
          validation: {
            required: "User type cannot be empty",
          },
        },
        mobilePhoneCode: {
          label: "Area Code",
          validation: {
            required: "Please select the area code",
          },
          placeholder: "Area Code",
        },
        mobilePhoneNumber: {
          label: "Mobile Number",
          validation: {
            required: "Please enter the mobile number",
            mobile: "Please enter a valid mobile number",
          },
          placeholder:
            "Enter the mobile number, employees will use this number to log in",
        },
        telephoneNumber: {
          label: "Office Phone",
          validation: {
            max: "Office phone cannot exceed {length} characters",
          },
          placeholder: "Enter the employee's office phone",
        },
        emailAddress: {
          label: "Email",
          validation: {
            required: "Please enter the employee's email",
            email: "Please enter a valid email",
            max: "Email cannot exceed 100 characters",
          },
          placeholder: "Enter the employee's email",
        },
        gender: {
          label: "Gender",
          validation: {
            required: "Please select the employee's gender",
          },
        },
        birthday: {
          label: "Date of Birth",
          validation: {
            required: "Please select the employee's date of birth",
          },
          placeholder: "Select the employee's date of birth",
        },
        induction: {
          label: "Date of Joining",
          validation: {
            required: "Please select the employee's date of joining",
          },
          placeholder: "Select the employee's date of joining",
        },
        master: {
          label: "Primary Account",
          placeholder: "Select whether it is the primary account",
        },
        roleIds: {
          label: "Roles",
          placeholder: "Select employee roles",
        },
        headImg: {
          label: "Avatar",
          placeholder: "Select the employee's avatar",
        },
        company: {
          label: "Affiliated Company",
          placeholder: "Select the employee's affiliated company",
          placeholderFirst:
            "Please select the employee's affiliated company first",
          required: "Affiliated company cannot be empty",
          activeStatus: {
            label: "Employment Status",
            placeholder: "Select the employee's employment status",
          },
          isCurrent: {
            label: "Current Enterprise",
            placeholder: "Is the employee part of the current enterprise",
          },
          joinGrantPoints: {
            label: "Participate in Point Distribution",
            placeholder: "Does the employee participate in point distribution",
          },
        },
        deptId: {
          label: "Department",
          placeholder: "Select the employee's department",
        },
        sendMsg: {
          label: "Send Notification to Activate Welfare Platform",
        },
        balance: {
          label: "Remaining {name}",
          returnBtn: "Recycle {name}",
        },
      },
      addModal: {
        title: "Create Employee",
        single: {
          tab: "Single Create",
        },
        multi: {
          tab: "Batch Create",
          tips: "Please download the template, fill it out, and upload it",
          downloadFile: "Batch Create Employees",
          required: "Please upload the batch creation file",
        },
      },
      editModal: {
        title: "Edit Employee",
      },
      returnModal: {
        title: "Recycle {name}",
        name: "Employee",
        currency: "Currency",
        balance: "Remaining {name}",
        points: "Recycle Quantity",
        pointsMin: "Recycle quantity cannot be less than 0",
        pointsRequired: "Recycle quantity cannot be empty",
        remark: "Remark",
        remarkMax: "Remark cannot exceed {length} characters",
      },
      leaveModal: {
        title: "Employee Resignation",
        tip: "Please confirm whether to resign the employee",
        returnCoin: "Whether to recycle {name} employee",
      },
    },
    orgList: {
      tab: "Organization Structure Management",
      addBtn: "Import",
      tips: "* The organization structure of the enterprise is connected to DingTalk. To ensure data synchronization, please go to DingTalk to make modifications if needed.",
    },
  },
  // 商品管理
  productManagement: {
    // 商品分类
    category: {
      form: {
        categoryName: {
          label: "Product Category",
          placeholder: "Enter product category",
          validation: {
            required: "Please enter product category",
            max: "Product category cannot exceed 100 characters",
          },
        },
        categoryParentCode: {
          label: "Parent Category",
          placeholder: "Select parent category",
          validation: {},
        },
        sort: {
          label: "Sort Order",
          placeholder: "Enter sort order",
          validation: {
            required: "Please enter sort order",
            number: "Please enter a valid number",
          },
        },
        state: {
          label: "Enable/Disable",
          validation: {
            required: "Please select whether to enable",
          },
        },
        icon: {
          label: "Category Image",
        },
        description: {
          label: "Category Description",
          placeholder: "Enter category description",
          validation: {
            max: "Category description cannot exceed 500 characters",
          },
        },
        remark: {
          label: "Category Overview",
          placeholder: "Enter category overview",
          validation: {
            max: "Category overview cannot exceed 500 characters",
          },
        },
        back: "Back",
        reset: "Reset",
        submit: "Submit",
        success: {
          add: "Product category added successfully",
          edit: "Product category modified successfully",
        },
      },
      list: {
        title: "Product Categories",
        search: "Enter product category",
        add: "Add Product Category",
        import: "Import",
        export: "Export",
        cols: {
          sort: "No",
          categoryName: "Category Name",
          icon: "Category Icon",
          description: "Category Description",
          categoryParentCode: "Parent Category",
          remark: "Category Overview",
          createTime: "Creation Time",
          state: "Enable Status",
        },
      },
    },
    // 商品规格
    spec: {
      list: {
        title: "All Specifications",
        add: "Add Specification",
        enable: "Available",
        disabled: "Disabled",
        search: "Enter name or other information",
        cols: {
          sort: "No",
          attributeName: "Specification Name",
          remark: "Attribute Description",
          attributeTypeName: "Attribute Type",
          attributeValueTypeName: "Attribute Value Type",
          state: "Attribute Status",
          createTime: "Creation Time",
        },
      },
      form: {
        attributeName: {
          label: "Specification Name",
          placeholder: "Enter name",
          validation: {
            required: "Please enter specification name",
            max: "Specification name cannot exceed 100 characters",
          },
          note: "Using the specification name as SKU",
        },
        state: {
          label: "Status",
          placeholder: "Select status",
          validation: {
            required: "Please select status",
          },
        },
        attributeType: {
          label: "Attribute Type",
          placeholder: "Select attribute type",
          validation: {
            required: "Please select attribute type",
          },
        },
        attributeValueType: {
          label: "Attribute Value Type",
          placeholder: "Select attribute value type",
          validation: {
            required: "Please select attribute value type",
          },
        },
        sort: {
          label: "Sort Order",
          placeholder: "Enter sort order",
          validation: {
            required: "Please enter sort order",
            number: "Please enter a valid number",
          },
        },
        isRequired: {
          label: "Required",
          placeholder: "Select whether it is required",
          validation: {
            required: "Please select whether it is required",
          },
        },
        isWholeLine: {
          label: "Display on Whole Line",
          placeholder: "Select whether to display on whole line",
          validation: {
            required: "Please select whether to display on whole line",
          },
        },
        remark: {
          label: "Specification Description",
          placeholder: "Enter specification description",
          validation: {
            max: "Specification description cannot exceed 500 characters",
          },
        },
        reset: "Reset",
        submit: "Submit",
        success: {
          add: "Product specification added successfully",
          edit: "Product specification modified successfully",
        },
      },
    },
    // 促销标签
    promotionalLabel: {
      list: {
        search: "Enter name or other information",
        title: "Promotion Tags",
        add: "Create New Promotion Tag",
        cols: {
          id: "ID",
          img: "Image",
          name: "Tag Name",
          type: "Tag Type",
          note: "Note",
          state: "Status",
        },
      },
      form: {
        name: {
          label: "Tag Name",
          placeholder: "Enter tag name",
          placeholderSelect: "Select promotion tag",
          required: "Tag name cannot be empty",
          max: "Tag name cannot exceed {length} characters",
        },
        icon: {
          label: "Tag Image",
        },
        state: {
          label: "Status",
        },
        promotionType: {
          label: "Tag Type",
          placeholder: "Select tag type",
          required: "Tag type cannot be empty",
        },
        remark: {
          label: "Tag Description",
          placeholder: "Enter tag description",
          max: "Tag description cannot exceed {length} characters",
        },
      },
    },
    // 商品管理
    product: {
      list: {
        supplier: "Supplier",
        supplierPlaceholder: "All",
        number: "Product Number",
        numberPlaceholder: "Product Number/Product Code",
        brand: "Product Brand",
        brandPlaceholder: "Select Product Brand",
        name: "Product Name",
        namePlaceholder: "Enter Product Name",
        type: "Product Type",
        typePlaceholder: "Select Product Type",
        classification: "Product Classification",
        classificationPlaceholder: "Select Product Classification",
        createTime: "Creation Time",
        createTimePlaceholder: "Select Creation Time",
        AddTime: "Shelf Time",
        AddTimePlaceholder: "Select Shelf Time",
        shelfTime: "Unshelf Time",
        shelfTimePlaceholder: "Select Unshelf Time",
        title: "All Products",
        title2: "Product List",
        cols: {
          sort: "No",
          img: "Product Image",
          stock: "Stock",
          num: "Sold Quantity",
          recommend: "Recommend",
          status: "Status",
        },
        add: "New Product",
        batchRec: "Batch Recommend",
        tabAll: "All",
        tabAudit: "Pending Review",
        tabAdd: "On Sale",
        tabShelf: "Off Shelf",
        upShelf: "On Sale",
        downShelf: "Off Shelf",
        selectProduct: "Please select the product to operate on first!",
      },
      detail: {
        invalidProductNo: "Invalid product code!",
        baseInfo: {
          title: "Basic Information",
          productName: {
            label: "Name",
            placeholder: "Enter product name",
            required: "Product name cannot be empty",
            max: "Product name cannot exceed {length} characters",
            tips: "Product Name",
          },
          categoryCode: {
            label: "Category",
            placeholder: "Select category",
            required: "Category cannot be empty",
          },
          productType: {
            label: "Type",
            placeholder: "Select product type",
            required: "Product type cannot be empty",
          },
          advertisement: {
            label: "Ad Slogan",
            placeholder: "Enter ad slogan",
            max: "Ad slogan cannot exceed {length} characters",
          },
          keywords: {
            label: "Search Keywords",
            placeholder: "Enter search keywords",
            max: "Keywords cannot exceed {length} characters",
          },
          supplierCode: {
            label: "Supplier",
            placeholder: "Select supplier",
            required: "Supplier cannot be empty",
          },
          freightTemplateId: {
            label: "Freight",
            placeholder: "Select freight template",
            required: "Freight template cannot be empty",
          },
          isRefund: {
            label: "Allow Refund",
            placeholder: "",
            required: "Refund option cannot be empty",
          },
          pictureUrl: {
            label: "Main Image",
            placeholder: "",
            required: "Main image cannot be empty",
          },
          brandCode: {
            label: "Brand",
            placeholder: "Select brand",
            required: "Brand cannot be empty",
            add: "Add Brand",
          },
          taxPoint: {
            label: "Tax Point",
            placeholder: "Enter tax point",
            number: "Enter a valid number",
          },
          isRecommend: {
            label: "Recommended",
          },
          link: {
            label: "Link",
            placeholder: "Supplier Product Link",
            max: "link cannot exceed {length} characters",
          },
          shelfTime: {
            label: "Shelf Time",
            placeholder: "Select shelf time",
            required: "Shelf time cannot be empty",
          },
        },
        spec: {
          title: "Product Specifications",
          add: "Add Specification",
          tipsAlways: "You can add up to 2 specifications at most.",
          tips: "To modify product specifications, please save the SKU list first!",
          existed: "This product specification already exists",
          emptySpecsName:
            "Please select the {sn} group of product specifications' 【Specification Name】",
          emptySpecsValue:
            "Please enter the {sn} group of product specifications' {sn2}th 【Specification Value】",
          specsName: {
            label: "Name",
            placeholder: "Select specification name",
            required: "Specification name cannot be empty",
          },
          specsValue: {
            label: "Value",
            placeholder: "Enter specification value",
            required: "Specification value cannot be empty",
            max: "Specification value cannot exceed {length} characters",
          },
        },
        sku: {
          title: "SKU Information",
          tips: "Please edit non-existent SKU information to disable status.",
          saveTips: "Please save the SKU list first!",
          edit: "Edit SKU",
          save: "Save SKU",
          enable: "Enable",
          disable: "Disable",
          enabled: "Enabled",
          disabled: "Disabled",
          emptySpecs: "Please add product specifications first",
          emptySku: "Please add product SKU information",
          emptySpec:
            "Please select the 【{label}】 value of the {sn}th row in the SKU list",
          emptySkuName:
            "Please enter the 【Attribute Name】 of the {sn}th row in the SKU list",
          emptySalesPrice:
            "Please enter the 【Payment Price】 of the {sn}th row in the SKU list",
          emptyStock:
            "Please enter the 【Stock】 of the {sn}th row in the SKU list",
          invalidBuyCount:
            "The 【Minimum Purchase Quantity】 of the {sn}th row in the SKU list is greater than the 【Maximum Purchase Quantity】!",
          productNo: {
            label: "Product Number",
            placeholder: "Auto-generated",
          },
          attr: {
            label: "Attribute",
            placeholder: "Select attribute value",
          },
          skuName: {
            label: "Attribute Name",
            placeholder: "Enter attribute name",
            required: "Attribute name cannot be empty",
            max: "Attribute name cannot exceed {length} characters",
          },
          salesPrice: {
            label: "Payment Price",
            placeholder: "Enter payment price",
            number: "Enter a valid number",
          },
          pictureUrl: {
            label: "Main Image",
          },
          stock: {
            label: "Stock",
            placeholder: "Enter stock",
            number: "Enter a valid number",
          },
          minBuyCount: {
            label: "Minimum Purchase Quantity",
            placeholder: "Enter minimum purchase quantity",
            number: "Enter a valid number",
          },
          maxBuyCount: {
            label: "Maximum Purchase Quantity",
            placeholder: "Enter maximum purchase quantity",
            number: "Enter a valid number",
          },
        },
        others: {
          title: "Other",
          detail: "Product Details",
          spec: "Specifications",
          exchange: "Exchange Process",
          afterSales: "After-sales Guarantee",
        },
        success: {
          add: "Saved successfully",
          edit: "Modified successfully",
        },
      },
    },
  },
  // 供应商管理
  supplierManagement: {
    // 供应商管理
    supplier: {
      title: "All Suppliers",
      list: {
        title: "Added Suppliers",
        allTitle: "All Suppliers",
        location: "Supplier Location",
        province: "Province",
        city: "City",
        area: "Area",
        add: "Add Supplier",
        import: "Import",
        export: "Export",
        batchDelConfirm: "Are you sure you want to delete these records?",
        cols: {
          sort: "No",
          number: "Supplier Code",
          name: "Supplier Name",
          acct: "Company Contact Number",
          email: "Company Email",
          person: "Contact Person",
          createTime: "Creation Time",
          method: "Settlement Method",
        },
      },
      form: {
        info: "Basic Information",
        supplierName: "Name",
        supplierCode: "Code",
        supplierNamePlaceholder: "Supplier Name",
        supplierCodePlaceholder: "Supplier Code",
        supplierNameRequired: "Supplier Name cannot be empty",
        supplierCodeRequired: "Supplier Code cannot be empty",
        supplierNameMax: "Supplier Name cannot exceed {length} characters",
        supplierCodeMax: "Supplier Code cannot exceed {length} characters",
        supplierNameSimple: "Abbreviation",
        supplierNameSimplePlaceholder: "Supplier Abbreviation",
        supplierNameSimpleRequired: "Supplier Abbreviation cannot be empty",
        supplierNameSimpleMax:
          "Supplier Abbreviation cannot exceed {length} characters",
        province: "Region",
        provincePlaceholder: "Province",
        provinceRequired: "select province",
        city: "City/District",
        cityPlaceholder: "City",
        cityRequired: "select city",
        paymentMethod: "Settlement",
        paymentMethodPlaceholder: "Settlement method (days)",
        paymentMethodRequired: "Fill in the settlement method",
        rAddress: "Common Return Address",
        returnPhone: "Mobile",
        returnPhonePlaceholder: "Mobile number",
        returnPhoneRequired: "Mobile number cannot be empty",
        returnPhoneReg: "Enter a valid mobile",
        returnReceiver: "Recipient",
        returnReceiverPlaceholder: "Recipient",
        returnReceiverRequired: "Recipient cannot be empty",
        returnReceiverMax: "Recipient cannot exceed {length} characters",
        returnAddress: "Address",
        returnAddressPlaceholder: "Shipping address",
        returnAddressRequired: "Shipping address cannot be empty",
        returnAddressMax: "Shipping address cannot exceed {length} characters",
        company: "Company Details",
        icon: "Logo Image",
        iconPlaceholder: "",
        iconRequired: "Supplier Logo Image cannot be empty",
        detailAddress: "Address",
        detailAddressPlaceholder: "Detailed address",
        detailAddressRequired: "Detailed address cannot be empty",
        detailAddressMax: "Detailed address cannot exceed {length} characters",
        zipCode: "Zip Code",
        zipCodePlaceholder: "Zip code",
        zipCodeMax: "Zip code cannot exceed {length} characters",
        contactPerson: "Contact",
        contactPersonPlaceholder: "Contact person's name",
        contactPersonRequired: "Contact cannot be empty",
        contactPersonMax: "Contact cannot exceed {length} characters",
        contactPhone: "Phone",
        contactPhonePlaceholder: "Phone number",
        contactPhoneRequired: "Phone number cannot be empty",
        contactPhoneMax: "Phone number cannot exceed {length} characters",
        contactEmail: "Email",
        contactEmailPlaceholder: "Email Address",
        contactEmailRequired: "Email cannot be empty",
        contactEmailMax: "Email cannot exceed {length} characters",
        contactEmailType: "Please enter a valid email address",
        businessScope: "Scope",
        businessScopePlaceholder: "Please enter introduction information",
        businessScopeRequired: "Business scope cannot be empty",
        businessScopeMax: "Business scope cannot exceed {length} characters",
        financial: "Financial Information",
        taxNumber: "Tax Number",
        taxNumberPlaceholder: "Tax number",
        taxNumberRequired: "Tax number cannot be empty",
        taxNumberMax: "Tax number cannot exceed {length} characters",
        invoiceHead: "Invoice Title",
        invoiceHeadPlaceholder: "Invoice title",
        invoiceHeadRequired: "Invoice title cannot be empty",
        invoiceHeadMax: "Invoice title cannot exceed {length} characters",
        bank: "Bank",
        bankPlaceholder: "Bank Name",
        bankRequired: "Bank cannot be empty",
        bankMax: "Bank cannot exceed {length} characters",
        bankNumber: "Account",
        bankNumberPlaceholder: "Bank account number",
        bankNumberRequired: "Account cannot be empty",
        bankNumberMax: "Account cannot exceed {length} characters",
        financePhoneNumber: "Phone",
        financePhoneNumberPlaceholder: "Phone number",
        financePhoneNumberRequired: "Phone cannot be empty",
        financePhoneNumberMax: "Phone cannot exceed {length} characters",
        companyAddress: "Address",
        companyAddressPlaceholder: "Company address",
        companyAddressRequired: "Company address cannot be empty",
        companyAddressMax: "Company address cannot exceed {length} characters",
        customInfo: "Customer Information",
        adminAccount: "Admin",
        adminAccountPlaceholder: "Admin account",
        adminAccountRequired: "Admin account cannot be empty",
        adminAccountMax: "Admin account cannot exceed {length} characters",
        principal: "Principal",
        principalPlaceholder: "Select the salesperson",
        principalRequired: "Principal cannot be empty",
        supplierLevel: "Level",
        supplierLevelPlaceholder: "Supplier level",
        supplierLevelRequired: "Level cannot be empty",
        signingTime: "Signing Time",
        signingTimePlaceholder: "Signing Time",
        signingTimeRequired: "Signing time cannot be empty",
        remark: "Remarks",
        remarkPlaceholder: "Please fill in remark information",
        remarkMax: "Remarks cannot exceed {length} characters",
        success: {
          add: "Save successful",
          edit: "Edit successful",
        },
      },
    },
    // 品牌管理
    brand: {
      list: {
        title: "Added Brands",
        add: "Add Brand",
        import: "Import",
        export: "Export",
        search: "Brand Name",
        enable: "Enabled",
        disabled: "Disabled",
        cols: {
          sort: "No",
          logo: "LOGO",
          brandName: "Brand Name",
          account: "Supplier Admin Account",
          createBy: "Created By",
          remark: "Brand Overview",
          state: "Status",
          createTime: "Creation Time",
        },
      },
      form: {
        brandCode: {
          label: "Brand Code",
          placeholder: "Please fill in the brand code",
          validation: {
            required: "Please enter the brand code",
            max: "Brand code cannot exceed 100 characters",
          },
        },
        brandName: {
          label: "Brand Name",
          placeholder: "Please fill in the brand name",
          validation: {
            required: "Please enter the brand name",
            max: "Brand name cannot exceed 100 characters",
          },
        },
        website: {
          label: "Brand Website",
          placeholder: "Please fill in the brand website",
          validation: {
            required: "Please enter the brand website",
            max: "Brand website cannot exceed 500 characters",
          },
        },
        icon: {
          label: "Brand Logo",
        },
        sort: {
          label: "Sort",
          placeholder: "Please enter the sort order",
          validation: {
            required: "Please enter the sort order",
            number: "Please enter a valid number",
          },
        },
        state: {
          label: "Status",
          placeholder: "Please select the status",
          validation: {
            required: "Please select the status",
          },
        },
        isRecommend: {
          label: "Recommended",
          placeholder: "Please select whether to recommend",
          validation: {
            required: "Please select whether to recommend",
          },
        },
        remark: {
          label: "Remark",
          placeholder: "Please fill in the remark",
          validation: {
            max: "Remark cannot exceed 500 characters",
          },
        },
        reset: "Reset",
        submit: "Submit",
        success: {
          add: "Brand added successfully",
          edit: "Brand modified successfully",
        },
      },
    },
    // 优惠券管理
    coupon: {
      list: {
        title: "Coupon Management",
        add: "Add Coupon",
        search: "Coupon Name",
        cols: {
          sort: "No",
          name: "Name",
          couponId: "Code",
          type: "Type",
          total: "Quantity",
          status: "Status",
          discount: "Discount / Offs",
          min: "Minimum Spending Amount",
          days: "Valid Days",
          startTime: "Start Time",
          endTime: "End Time",
          content: "Introduction",
        },
      },
      form: {
        name: {
          label: "Coupon Name",
          placeholder: "Please fill in the coupon name",
          required: "Please enter the coupon name",
          max: "Coupon name cannot exceed {length} characters",
        },
        couponId: {
          label: "Coupon Code",
        },
        type: {
          label: "Coupon Type",
          placeholder: "Please select the coupon type",
          required: "Please select the coupon type",
        },
        total: {
          label: "Coupon Quantity",
          placeholder: "Please fill in the coupon quantity",
          required: "Please enter the coupon quantity",
        },
        status: {
          label: "Coupon Status",
          placeholder: "Please select the coupon status",
          required: "Please select the coupon status",
        },
        discount: {
          label1: "Coupon Percentage",
          label2: "Discount Amount",
          placeholder1: "Please fill in the percentage, e.g., 90 for 90%",
          placeholder2:
            "Please fill in the discount amount, in units of currency",
          required: "Please fill in the discount amount or percentage",
        },
        min: {
          label: "Minimum Spending Amount",
          placeholder:
            "Please fill in the minimum spending amount, in units of currency",
        },
        days: {
          label: "Valid Days",
          required: "Please fill in the valid days",
          placeholder: "Please fill in the valid days",
        },
        startTime: {
          label: "Start Time for Coupon Usage",
          placeholder: "Please select the start time for coupon usage",
        },
        endTime: {
          label: "End Time for Coupon Usage",
          placeholder: "Please select the end time for coupon usage",
        },
        content: {
          label: "Coupon Introduction",
          placeholder: "Please fill in the coupon introduction",
          required: "Please fill in the coupon introduction",
          max: "Coupon introduction cannot exceed {length} characters",
        },
      },
    },
  },
  // 订单管理
  orderManagement: {
    order: {
      list: {
        title: "Order List",
        filters: {
          orderId: {
            label: "Order ID",
            placeholder: "Please enter order ID",
          },
          supplier: {
            label: "Supplier",
            placeholder: "Please select a supplier",
          },
          orderAmount: {
            label: "Order Amount",
            placeholderMin: "Minimum Amount",
            placeholderMax: "Maximum Amount",
          },
          createTime: {
            label: "Order Time",
            placeholder: "Please select order time",
          },
          state: {
            label: "Order Status",
            placeholder: "Please select order status",
          },
          productName: {
            label: "Product Name",
            placeholder: "Please enter product name",
          },
          name: {
            label: "Recipient's Name",
            placeholder: "Please enter recipient's name",
          },
          phone: {
            label: "Recipient's Phone",
            placeholder: "Please enter recipient's phone",
          },
          productType: {
            label: "Product Type",
            placeholder: "Please select a category",
          },
        },
        cols: {
          orderId: "Order ID",
          createTime: "Submission Time",
          payPrice: "Actual Payment",
          productName: "Product Name",
          remark: "Order Remark",
          stateName: "Order Status",
          discountTypeName: "Promotion Type",
        },
      },
      detail: {
        stateTips: "Current Order Status",
        invalidOrderId: "Invalid Order ID!",
        confirm: "Refund",
        confirmRefund: "Are you sure you want to proceed with the refund?",
        refundSuccess: "Refund operation successful!",
        send: "Shipped",
        confirmSend: "Have you shipped the order?",
        sendSuccess: "Shipment successful!",
        progress: {
          title: "Order Progress",
          createOrder: "Submit Order",
          payOrder: "Pay Order",
          payOrder1: "Paid",
          payOrder2: "Unpaid",
          sendOrder: "Platform Shipment",
          sendOrder1: "Shipped",
          sendOrder2: "Not Shipped",
          receivedOrder: "Confirm Receipt",
          receivedOrder1: "Received",
          receivedOrder2: "Pending Receipt",
          doneOrder: "Complete Evaluation",
        },
        deliveryInfo: {
          title: "Shipping Information",
          expName: {
            label: "Company",
            placeholder: "Logistics company",
            validation: {
              required: "Please select logistics company",
            },
          },
          number: {
            label: "Number",
            placeholder: "Waybill number",
            validation: {
              required: "Please enter waybill number",
              max: "Waybill number cannot exceed {length} characters",
            },
          },
          ship: "Shipped",
          refund: "Refund",
        },
        receiverInfo: {
          title: "Recipient Information",
          cols: {
            consignee: "Recipient",
            phone: "Mobile Number",
            zipCode: "Postal Code",
            address: "Shipping Address",
          },
        },
        baseInfo: {
          title: "Basic Information",
          cols: {
            orderId: "Order ID",
            orderNumber: "Shipping Order Serial Number",
            account: "User Account",
            payWay: "Payment Method",
            orderSource: "Order Source",
            orderTypeName: "Order Type",
            deliveryMethod: "Delivery Method",
            expNumber: "Logistics Number",
            autoReceived: "Auto Confirm Receipt Time",
            orderAward: "Order Earned Coins",
            orderPoints: "Order Earned Growth Points",
            activityInfo: "Activity Information",
          },
        },
        invoiceInfo: {
          title: "Invoice Information",
          cols: {
            invoiceType: "Invoice Type",
            invoiceHead: "Invoice Title",
            invoiceContent: "Invoice Content",
            invoiceInfo: "Recipient Information",
          },
        },
        productInfo: {
          title: "Product Information",
          cols: {
            productUrl: "Product Image",
            productName: "Product Name",
            productPrice: "Price/Item Number",
            productPrice1: "Price",
            productPrice2: "Item Number",
            skuName: "Attributes",
            number: "Quantity",
            productStock: "Stock",
            subtotal: "Subtotal",
          },
          total: "Total: ",
        },
        feeInfo: {
          title: "Fee Information",
          cols: {
            aaa1: "Total for Products",
            freightPoints: "Shipping Fee",
            aaa2: "Coupon",
            aaa3: "Deductible Growth Points",
            aaa4: "Promotional Discount",
            aaa5: "Discount Amount",
            aaa6: "Total Order Amount",
            aaa7: "Amount Payable",
          },
        },
        operatingInfo: {
          title: "Operation Information",
          cols: {
            aaa1: "Operator",
            aaa2: "Operation Time",
            aaa3: "Order Status",
            aaa4: "Payment Status",
            aaa5: "Shipment Status",
            aaa6: "Remark",
          },
        },
      },
    },
    aftersales: {
      list: {
        title: "After-Sales Order List",
        cols: {
          id: "After-Sales ID",
          orderId: "Order ID",
          applyTime: "Application Time",
          name: "Product Name",
          money: "Refund Amount",
          reason: "Refund Reason",
          isReject: "Return or Not",
          status: "Approval Status",
          tips: "Are you sure you want to agree to the return?",
        },
      },
    },
  },
  // 资源中心
  resource: {
    list: {
      button: "Exchange Resources",
      balance: "Account Balance",
      back: "Background Check Coin",
      welfare: "Welfare Coin",
      survey: "Survey Coin",
      record: "Transaction Records",
      time: "Transaction Time",
      cols: {
        time: "Time",
        purpose: "Purpose",
        currency: "Currency",
        exchangePoints: "Amount",
        exchangeAfterPoints: "Balance",
      },
      tips: {
        cash: "Can only be obtained through offline recharge.<br>Can be used for settling background check services and can also be exchanged for other service currencies.",
        back: "{currency} for settling background check services.<br>Can be obtained by redeeming in the resource center.",
        wel: "{currency} can be given to employees to redeem welfare items.<br>Can be obtained by redeeming in the resource center.",
        survey:
          "{currency} can be used to purchase survey questions and the number of surveyed employees.<br>Can be obtained by redeeming in the resource center.",
      },
    },
    modal: {
      currency: "Target Currency",
      amount: "Exchange Amount",
      balance: "Balance Needed",
      surplus: "Balance after Exchange",
      warnTip: "Insufficient available balance for the current currency",
      amountMin: "Exchange amount must be greater than 0",
    },
  },
  // 会员中心
  member: {
    base: {
      tab: "Basic Information",
      form: {
        enterName: "Company Name",
        name: "Name",
        shortName: "Company Abbreviation",
        mobile: "Mobile Number",
        trade: "Industry",
        email: "Email",
        reportReceiveEmail: "Email for receive report",
        city: "City",
        mainAcct: "Main Account",
        avatar: "Avatar",
      },
      uploadBtn: "Select Avatar to Upload",
      submitBtn: "Submit Avatar",
      preview: "Avatar Preview",
      editPsd: "Change Password",
      editAvatar: "Change Avatar",
      psd: {
        loginPsd: "Login Password",
        loginPsdPh: "Enter current password",
        currentLogin: "Current Password",
        newLogin: "New Password",
        confirmLogin: "Confirm Password",
        payPsd: "Payment Password",
        currentPay: "Current Payment Password",
        newPay: "New Payment Password",
        confirmPay: "Confirm Password",
        tips: "New password and confirmation password do not match",
      },
    },
    acct: {
      staff: "Staff",
      tab: "Sub-Account",
      auth: "Authorization",
      time: "Last Login Time",
      start: "Enable",
      squareAudit: "Square Audit Permission",
      squareAuditTitle: "Welfare Platform Square Audit",
      squareAuditConfirmOpen:
        "Confirm to grant square audit permission to {name}?",
      squareAuditConfirmClose:
        "Confirm to revoke square audit permission from {name}?",
      create: "Create Sub-Account",
      initPsd: "Initial Password",
      initPsdPlaceholder:
        "Enter sub-account login password, at least 6 characters",
    },
    score: {
      tab: "HR Growth Points",
      tip: "HR growth points are the system's appreciation and reward for your daily work and can be used in the EC Mall.",
      useRecord: "Usage Records",
      noneUseRecord: "No usage records yet",
      table: {
        time: "Point Acquisition Time",
        source: "Source",
        number: "Quantity",
        rest: "Remaining",
      },
    },
    bind: {
      tab: "Ecological Binding",
      beisen: {
        account: "Vendor Account",
        secret: "Key",
        createConfirm:
          "After creating the key, you need to perform binding operations on the Beisen platform.",
        removeConfirm: "Are you sure to remove the binding?",
        create: "Create Key",
        view: "View Key",
        remove: "Remove Binding",
        copy: "Copy Key",
        tips: "Beisen Binding Operation Tips",
        help: "Usage Help",
      },
      moka: {
        noUrl: "Contact your business contact for binding if needed.",
        unbind: "Unbind",
        binded: "Already Bound",
      },
      feishu: {
        account: "Vendor Account",
        secret: "Key",
        logoFileName: "logo-feishu_hire-en.svg",
        create: "Create Key",
        view: "View Key",
        copy: "Copy Key",
        reset: "Reset Key",
        help: "Usage Help",
        tips: "FeiShu Hire Binding Operation Guide",
        createConfirm:
          "After creating the key, you need to perform binding operations on the FeiShu Hire system.",
        resetConfirm:
          "After the key is reset, you need to go to the background Settings of the FeiShu Hire system, delete and re-bind the account, do you want to continue?",
        cancelOperateBlockedMessage:
          "UTo avoid data discrepancies with the FeiShu Hire system, please do this through the FeiShu Hire system..",
        updatePackage: "Renewal package",
      },
    },
  },
  // 配送管理
  express: {
    company: {
      list: {
        cols: {
          start: "Enable",
          name: "Name",
        },
      },
    },
    cost: {
      filter: {
        area: "Area",
        supplier: "Supplier Name",
        amount: "Amount (cents)",
      },
      cols: {
        index: "Index",
      },
      modal: {
        title: "Express Delivery Fees",
        supplier: "Supplier",
      },
    },
  },
  // 系统管理
  system: {
    dic: {
      title: "Data Dictionary",
      search: "Dictionary Name, Code",
      add: "Add Dictionary",
      modal: "Edit Dictionary",
      cols: {
        index: "Index",
        sort: "Sort",
        dictType: "Dictionary Type",
        dictName: "Dictionary Name",
        dictCode: "Dictionary Code",
        dictIcon: "Dictionary Icon",
        state: "Status",
        remark: "Remark",
      },
      form: {
        dictType: {
          label: "Dictionary Type",
          placeholder: "Please select dictionary type",
          required: "Dictionary type cannot be empty",
        },
        dictCode: {
          label: "Dictionary Code",
          placeholder: "Please enter dictionary code",
          required: "Dictionary code cannot be empty",
          max: "Dictionary code cannot exceed {length} characters",
        },
        dictName: {
          label: "Dictionary Name",
          placeholder: "Please enter dictionary name",
          required: "Dictionary name cannot be empty",
          max: "Dictionary name cannot exceed {length} characters",
        },
        sort: {
          label: "Sort",
          placeholder: "Please enter sort order",
          required: "Sort order cannot be empty",
          number: "Sort order must be a valid number",
        },
        state: {
          label: "Status",
          placeholder: "Please select status",
          required: "Status cannot be empty",
        },
        dictIcon: {
          label: "Dictionary Icon",
        },
        remark: {
          label: "Remark",
          placeholder: "Please enter remark",
          max: "Remark cannot exceed {length} characters",
        },
      },
    },
    menu: {
      title: "Menu Management",
      search: "Menu Name",
      add: "Add Menu",
      modal: "Edit Menu",
      cols: {
        sort: "Sort",
        name: "Menu Name",
        href: "Link",
        sysType: "Platform Type",
      },
      form: {
        name: {
          label: "Menu Name",
          placeholder: "Please enter menu name",
          required: "Menu name cannot be empty",
          max: "Menu name cannot exceed {length} characters",
        },
        parentId: {
          label: "Parent Menu",
          placeholder: "Please select parent menu",
        },
        href: {
          label: "Link",
          placeholder: "Please enter link",
          max: "Link cannot exceed {length} characters",
        },
        sysType: {
          label: "Platform Type",
          placeholder: "Please select platform type",
          required: "Platform type cannot be empty",
        },
        type: {
          label: "Menu Type",
          placeholder: "Please select menu type",
          required: "Menu type cannot be empty",
        },
        sort: {
          label: "Sort",
          placeholder: "Please enter sort order",
          required: "Sort order cannot be empty",
          number: "Sort order must be a valid number",
        },
      },
    },
    role: {
      title: "Role Management",
      search: "Role Name",
      add: "Add Role",
      modal: "Edit Role",
      permission: "Bind Permissions",
      cols: {
        sort: "Sort",
        name: "Role Name",
        roleId: "Role Code",
        sysType: "Platform Type",
        remark: "Remark",
      },
      form: {
        name: {
          label: "Role Name",
          placeholder: "Please enter role name",
          required: "Role name cannot be empty",
          max: "Role name cannot exceed {length} characters",
        },
        roleId: {
          label: "Role Code",
          placeholder: "Please enter role code",
          required: "Role code cannot be empty",
          max: "Role code cannot exceed {length} characters",
        },
        sysType: {
          label: "Platform Type",
          placeholder: "Please select platform type",
          required: "Platform type cannot be empty",
        },
        remark: {
          label: "Remark",
          placeholder: "Please enter remark",
          max: "Remark cannot exceed {length} characters",
        },
      },
    },
    user: {
      title: "User Management",
      search: "Name",
      add: "Add User",
      modal: "Edit User",
      cols: {
        sort: "Sort",
        name: "Name",
        deptId: "Department",
        mobilePhoneNumber: "Mobile Number",
        gender: "Gender",
        emailAddress: "Email Address",
        points: "Points",
      },
      form: {
        name: {
          label: "Name",
          placeholder: "Please enter name",
          required: "Name cannot be empty",
          max: "Name cannot exceed {length} characters",
        },
        companyId: {
          label: "Affiliated Company",
          placeholder: "Please select affiliated company",
          required: "Affiliated company cannot be empty",
        },
        deptId: {
          label: "Department",
          placeholder: "Please select department",
          required: "Department cannot be empty",
        },
        userType: {
          label: "User Type",
          placeholder: "Please select user type",
          required: "User type cannot be empty",
        },
        roleIds: {
          label: "User Roles",
          placeholder: "Please select user roles",
          required: "User roles cannot be empty",
        },
        master: {
          label: "Master Account",
        },
        gender: {
          label: "Gender",
          placeholder: "Please select gender",
        },
        birthday: {
          label: "Date of Birth",
          placeholder: "Please select date of birth",
        },
        mobilePhoneNumber: {
          label: "Mobile Number",
          placeholder: "Please enter mobile number",
          required: "Mobile number cannot be empty",
          mobile: "Invalid mobile number format",
        },
        telephoneNumber: {
          label: "Office Landline",
          placeholder: "Please enter office landline",
        },
        emailAddress: {
          label: "Email Address",
          placeholder: "Please enter email address",
          required: "Email address cannot be empty",
          email: "Please enter a valid email address",
        },
        induction: {
          label: "Date of Joining",
          placeholder: "Please select date of joining",
        },
        sendMsg: {
          label: "Activation Notification",
        },
        userCompany: {
          label: "Company Information",
        },
      },
    },
    org: {
      title: "Organizational Structure",
      search: "Department Name",
      companyId: "Company",
      add: "Add Organizational Structure",
      modal: "Edit Organizational Structure",
      cols: {
        sort: "Sort",
        deptName: "Department Name",
        deptId: "Department ID",
        companyId: "Company ID",
        delFlag: "Status",
        remark: "Remark",
      },
      form: {
        companyId: {
          label: "Affiliated Company",
          placeholder: "Please select affiliated company",
          required: "Affiliated company cannot be empty",
        },
        deptName: {
          label: "Department Name",
          placeholder: "Please enter department name",
          required: "Department name cannot be empty",
          max: "Department name cannot exceed {length} characters",
        },
        deptId: {
          label: "Department ID",
          placeholder: "Please select department ID",
        },
        parentId: {
          label: "Parent Department",
          placeholder: "Please select parent department",
        },
        remark: {
          label: "Remark",
          placeholder: "Please enter remark",
          max: "Remark cannot exceed {length} characters",
        },
      },
    },
    conf: {
      title: "System Configuration",
      search: "System Configuration Name, Code",
      add: "Add System Configuration",
      modal: "Edit System Configuration",
      cols: {
        sort: "Sort",
        name: "Name",
        code: "Code",
        value: "Configuration Value",
        delFlag: "Status",
        isEncryption: "Encryption",
        remark: "Remark",
      },
      form: {
        code: {
          label: "Code",
          placeholder: "Please enter code",
          required: "Code cannot be empty",
          max: "Code cannot exceed {length} characters",
        },
        name: {
          label: "Name",
          placeholder: "Please enter name",
          required: "Name cannot be empty",
          max: "Name cannot exceed {length} characters",
        },
        value: {
          label: "Configuration Value",
          placeholder: "Please enter configuration value",
          required: "Configuration value cannot be empty",
          max: "Configuration value cannot exceed {length} characters",
        },
        sort: {
          label: "Sort",
          placeholder: "Please enter sort order",
          required: "Sort order cannot be empty",
          number: "Sort order must be a valid number",
        },
        state: {
          label: "Status",
          required: "Status cannot be empty",
        },
        isEncryption: {
          label: "Encrypt Data",
          required: "Encryption status cannot be empty",
        },
        remark: {
          label: "Remark",
          placeholder: "Please enter remark",
          max: "Remark cannot exceed {length} characters",
        },
      },
    },
    company: {
      title: "Company Management",
      search: "Company Name",
      add: "Add Company",
      modal: "Edit Company",
      cols: {
        sort: "Sort",
        name: "Company Name",
        nameShort: "Company Short Name",
        companyId: "Company ID",
        signType: "Signing Status",
        points: "Balance",
        contactName: "Contact Person",
        contactPhone: "Contact Phone",
        contactEmail: "Contact Email",
      },
      form: {
        base: {
          title: "Basic Information",
          name: {
            label: "Chinese Name",
            placeholder: "Please enter Chinese company name",
            required: "Chinese name cannot be empty",
            max: "Chinese name cannot exceed {length} characters",
          },
          companyId: {
            label: "Company Code",
          },
          nameShort: {
            label: "Chinese Short Name",
            placeholder: "Please enter Chinese short name",
            max: "Chinese short name cannot exceed {length} characters",
          },
          nameOther: {
            label: "Third Language Name",
            placeholder: "Please enter third language name",
            max: "Third language name cannot exceed {length} characters",
          },
          nameOtherShort: {
            label: "Third Language Short Name",
            placeholder: "Please enter third language short name",
            max: "Third language short name cannot exceed {length} characters",
          },
          parentCompanyId: {
            label: "Parent Company",
            placeholder: "Please select parent company",
          },
          categoryId: {
            label: "Customer Category",
            placeholder: "Please select customer category",
          },
          industry: {
            label: "Industry",
            placeholder: "Please select industry",
            required: "Industry cannot be empty",
          },
          businessUserId: {
            label: "Business Owner",
            placeholder: "Please select business owner",
          },
          scaleId: {
            label: "Company Scale",
            placeholder: "Please select company scale",
          },
          sourceId: {
            label: "Company Source",
            placeholder: "Please select company source",
          },
          typeId: {
            label: "Company Type",
            placeholder: "Please select company type",
          },
          taxCode: {
            label: "Unified Social Credit Code",
            placeholder: "Please enter unified social credit code",
            required: "Unified social credit code cannot be empty",
            max: "Unified social credit code cannot exceed {length} characters",
          },
          serviceUserId: {
            label: "Customer Service Owner",
            placeholder: "Please select customer service owner",
          },
          logo: {
            label: "LOGO",
          },
          country: {
            label: "Country",
            placeholder: "Please enter country",
            max: "Country cannot exceed {length} characters",
          },
          province: {
            label: "Address",
            placeholder: "Province",
            required: "Address information is required",
          },
          city: {
            label: "City",
            placeholder: "City",
            required: "Address information is required",
          },
          district: {
            label: "District",
            placeholder: "District",
            required: "Address information is required",
          },
          address: {
            label: "Detailed Address",
            placeholder: "Please enter detailed address",
            max: "Detailed address cannot exceed {length} characters",
          },
          intro: {
            label: "Introduction",
            placeholder: "Please enter company introduction",
            max: "Introduction cannot exceed {length} characters",
          },
        },
        hrList: {
          title: "Contact Information",
          add: "Add Contact Person",
          cols: {
            name: "Name",
            namePlaceholder: "Please enter name",
            nameRequired: "Name cannot be empty",
            nameMax: "Name cannot exceed {length} characters",
            phone: "Phone Number",
            phonePlaceholder: "Please enter phone number",
            phoneRequired: "Phone number cannot be empty",
            phoneMax: "Phone number cannot exceed {length} characters",
            master: "Master Account",
            masterOne: "A contact person can only be the master account",
            email: "Email",
            emailPlaceholder: "Please enter email",
            emailRequired: "Email cannot be empty",
            emailMax: "Email cannot exceed {length} characters",
            emailType: "Please enter a valid email address",
          },
        },
        signList: {
          title: "Signing Information",
          add: "Add Signing Information",
          cols: {
            companyId: "Company Code",
            companyIdPlaceholder: "Please enter company code",
            companyIdRequired: "Company code cannot be empty",
            companyIdMax: "Company code cannot exceed {length} characters",
            type: "Signing Type",
            typePlaceholder: "Select type",
            typeRequired: "Signing type cannot be empty",
            points: "Amount",
            pointsPlaceholder: "Please enter amount",
            pointsRequired: "Amount cannot be empty",
            status: "Status",
            statusPlaceholder: "Select status",
            statusRequired: "Status cannot be empty",
            startTime: "Start Time",
            startTimePlaceholder: "Select signing start time",
            endTime: "End Time",
            endTimePlaceholder: "Select signing end time",
            endTimeValid: "End time must be later than start time",
          },
        },
        resource: {
          title: "Resource Information",
          backToneCoin: {
            label: "Background Check Coin",
            placeholder: "Please enter background check coin",
          },
          benefitsCoin: {
            placeholder: "Please enter {name}",
          },
          points: {
            label: "Points",
            placeholder: "Please enter points",
          },
          surveyCoin: {
            label: "Survey Coin",
            placeholder: "Please enter survey coin",
          },
        },
        dictCode: {
          label: "Dictionary Code",
          placeholder: "Please enter dictionary code",
          required: "Dictionary code cannot be empty",
          max: "Dictionary code cannot exceed {length} characters",
        },
        dictName: {
          label: "Dictionary Name",
          placeholder: "Please enter dictionary name",
          required: "Dictionary name cannot be empty",
          max: "Dictionary name cannot exceed {length} characters",
        },
        sort: {
          label: "Sort",
          placeholder: "Please enter sort order",
          required: "Sort order cannot be empty",
          number: "Sort order must be a valid number",
        },
        state: {
          label: "Status",
          placeholder: "Select status",
          required: "Status cannot be empty",
        },
        dictIcon: {
          label: "Dictionary Icon",
        },
        remark: {
          label: "Remark",
          placeholder: "Please enter remark",
          max: "Remark cannot exceed {length} characters",
        },
      },
    },
    // 推荐banner管理
    banner: {
      title: "Banner Management",
      bannerType: "Banner Type",
      add: "Add Banner",
      modal: "Edit Banner",
      cols: {
        sort: "Sort",
        bannerPictureUrl: "Image",
        bannerLink: "Link Address",
        bannerType: "Type",
        state: "Status",
        remark: "Remark",
      },
      form: {
        bannerType: {
          label: "Type",
          placeholder: "Please select type",
          required: "Type cannot be empty",
        },
        bannerPictureUrl: {
          label: "Image",
          placeholder: "Please select Banner image",
          required: "Image cannot be empty",
        },
        bannerLink: {
          label: "Link Address",
          placeholder: "Please enter link address",
          max: "Link address cannot exceed {length} characters",
        },
        sort: {
          label: "Sort",
          placeholder: "Please enter sort order",
          required: "Sort order cannot be empty",
          number: "Sort order must be a valid number",
        },
        state: {
          label: "Status",
          placeholder: "Please select status",
          required: "Status cannot be empty",
        },
        remark: {
          label: "Remark",
          placeholder: "Please enter remark",
          max: "Remark cannot exceed {length} characters",
        },
      },
    },
    exchange: {
      list: {
        title: "Enterprise Exchange Rates",
        company: "Company Name",
        from: "Source Currency",
        to: "Converted Currency",
        ratio: "Exchange Ratio",
        remark: "Remark",
      },
    },
    benefits: {
      add: "Add Benefit",
      search: "Benefit Name",
      title: "System Benefits",
      distribution: {
        title: "Allocate System Benefits",
        btn: "Batch Allocate",
        companyIds: "Allocate to Companies",
        companyIdsRequired: "Companies for allocation cannot be empty",
        companyIdsPlaceholder: "Please select companies for allocation",
        emptySelected: "Please select benefits to allocate",
      },
    },
  },
  // 审核
  audit: {
    pass: "Approved",
    rejected: "Rejected",
    pending: "Pending Review",
    cols: {
      index: "Index",
      publisher: "Publisher",
      content: "Published Content",
      time: "Publish Time",
      status: "Review Status",
      reason: "Rejection Reason",
    },
    modal: {
      awardNum: "Reward {unit}",
      tip: "Available {value} {unit}",
      payPwd: "Payment Password",
    },
  },
};
