<template>
  <div class="bind-wrap">
    <!--MOKA-->
    <div class="bind-item moka" :class="mokaOpenid ? 'bind' : 'unbind'" @click="onMoka">
      <a-spin :spinning="mokaLoading">
        <img class="bind-icon" src="@/assets/images/moka-logo.png" alt="Moka" />
        <div class="bind-btn-wrap">
          <a v-if="mokaOpenid" href="javascript: void(0);" class="bind-btn" @click="onMokaRemove">
            {{ $t("member.bind.moka.unbind") }}
          </a>
        </div>
        <div class="bind-badge" v-if="mokaOpenid">
          {{ $t("member.bind.moka.binded") }}
        </div>
      </a-spin>
    </div>

    <!--北森-->
    <div class="bind-item beisen">
      <a-spin :spinning="beisenLoading">
        <i class="bind-icon iconfont icon-beisen-logo"></i>
        <div class="bind-btn-wrap">
          <template v-if="beisen">
            <a href="javascript: void(0);" class="bind-btn" @click="onBeisen(true)">
              {{ $t("member.bind.beisen.view") }}
            </a>
          </template>
          <a href="javascript: void(0);" class="bind-btn" @click="onBeisenCreate" v-else>
            {{ $t("member.bind.beisen.create") }}
          </a>
          <a href="javascript: void(0);" class="bind-btn" @click="onTips(2)">
            {{ $t("member.bind.beisen.help") }}
          </a>
        </div>
      </a-spin>
    </div>

    <!--飞书-->
    <div class="bind-item feishu">
      <a-spin :spinning="feishuLoading">
        <img class="bind-icon bind-icon-feishu" src="@/assets/images/logo-feishu_hire-cn.svg" alt="FeiShu" />
        <div class="bind-btn-wrap">
          <a href="javascript: void(0);" class="bind-btn bind-btn-danger" @click="handleFeiShuSecretReplacement"
            v-if="feiShuSecret && feiShuSecret.length > 0">
            {{ $t("member.bind.feishu.reset") }}
          </a>
          <a href="javascript: void(0);" class="bind-btn" @click="handleFeiShuSecretDialog(true)"
            v-if="feiShuSecret && feiShuSecret.length > 0">
            {{ $t("member.bind.feishu.view") }}
          </a>
          <a href="javascript: void(0);" class="bind-btn" @click="handleFeiShuSecretCreation" v-else>
            {{ $t("member.bind.feishu.create") }}
          </a>
          <a href="javascript: void(0);" class="bind-btn" @click="onTips(3)"
            v-if="feiShuSecret && feiShuSecret.length > 0">
            {{ $t("member.bind.feishu.help") }}
          </a>
          <a href="javascript: void(0);" class="bind-btn" @click="handleFeiShuPackageUpdate"
            v-if="feiShuSecret && feiShuSecret.length > 0">
            {{ $t("member.bind.feishu.updatePackage") }}
          </a>
        </div>
      </a-spin>
    </div>

    <!--北森查看密钥弹窗-->
    <a-modal :maskClosable="false" v-model="beisenVisible" :title="$t('member.bind.beisen.view')" :width="500"
      :destroyOnClose="true">
      <div class="member-bind-modal-content">
        <div class="secret-title">
          {{ $t("member.bind.beisen.account") }}：
        </div>
        <div class="secret-value">
          <span>{{ account }}</span>
        </div>

        <br />

        <div class="secret-title">
          {{ $t("member.bind.beisen.secret") }}：
        </div>
        <div class="secret-value">
          <span>{{ beisen }}</span>
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="onBeisenCopy">
          {{ $t("member.bind.beisen.copy") }}
        </a-button>
      </template>
    </a-modal>

    <!--北森使用帮助弹窗-->
    <a-modal :maskClosable="false" v-model="beisenTipsVisible" title="" :width="650" :destroyOnClose="true">
      <img ref="beisenTipsRef" src="@/assets/images/beisen-bind-tips.png" :alt="$t('member.bind.beisen.tips')"
        :title="$t('button.previewBig')" width="100%" v-viewer="{ navbar: false }" @click="onBeisenTipsClick" />
      <template slot="footer">
        <a-button type="primary" @click="beisenTipsVisible = false">
          {{ $t("button.close") }}
        </a-button>
      </template>
    </a-modal>

    <!--飞书查看密钥弹窗-->
    <a-modal :maskClosable="false" v-model="feiShuSecretDialogVisible" :title="$t('member.bind.feishu.view')"
      :width="500" :destroyOnClose="true">
      <div class="member-bind-modal-content">
        <div class="secret-title">
          {{ $t("member.bind.feishu.account") }}：
        </div>
        <div class="secret-value">
          <span>{{ account }}</span>
        </div>

        <br />

        <div class="secret-title">
          {{ $t("member.bind.feishu.secret") }}：
        </div>
        <div class="secret-value">
          <span>{{ feiShuSecret }}</span>
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="handleFeiShuSecretCopy">
          {{ $t("member.bind.feishu.copy") }}
        </a-button>
      </template>
    </a-modal>

    <!--飞书使用帮助弹窗-->
    <a-modal :maskClosable="false" v-model="feiShuTipsVisible" :title="$t('member.bind.feishu.tips')" :width="650"
      :destroyOnClose="true">
      <img ref="feiShuHireTipsRef" src="@/assets/images/feishu-hire-bind-tips.png" :alt="$t('member.bind.feishu.tips')"
        :title="$t('button.previewBig')" width="100%" v-viewer="{ navbar: false }" @click="onFeiShuHireTipsClick" />
      <template slot="footer">
        <a-button type="primary" @click="feiShuTipsVisible = false">
          {{ $t("button.close") }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { confirmModal } from "@/utils/tools";
import {
  createBeisenSecret,
  delBeisenSecret,
  delMokaBind,
  getBeisenSecret,
  getMokaBind,
  getMokaBindData,
  getFeiShuSecret,
  createFeiShuSecret,
  reSetFeiShuSecret,
  updateFeiShuPackage
} from "@/pages/member/api";
import { showMsg } from "@/utils";

export default {
  name: "Ecology",
  data() {
    return {
      account: this.$store.state.user.info.emailAddress,
      beisen: null,
      beisenVisible: false,
      beisenLoading: false,
      beisenTipsVisible: false,
      mokaLoading: false,
      mokaOpenid: null,
      mokaUrl: null,

      feishuLoading: false,
      feiShuSecret: '',
      feiShuSecretDialogVisible: false,
      feiShuTipsVisible: false
    };
  },
  methods: {
    // 创建北森密钥
    onBeisenCreate() {
      if (this.beisenLoading) return;
      confirmModal(
        this.$t("member.bind.beisen.createConfirm"),
        "",
        async () => {
          const res = await createBeisenSecret();
          if (res.flag !== 0) {
            showMsg(res);
            this.beisenLoading = false;
            return;
          }
          this.beisen = res.data;
          showMsg({
            flag: 0,
            msg: this.$t("showMsg.success.operation")
          });
          this.beisenLoading = false;
          await this.getBeisenKey();
          this.onBeisen(true);
        },
        () => {
          this.beisenLoading = false;
        }
      );
    },
    // 查看北森密钥
    onBeisen(visible) {
      this.beisenVisible = visible;
    },
    // 移除北森密钥
    onBeisenRemove() {
      if (this.beisenLoading) return;
      confirmModal(
        this.$t("member.bind.beisen.removeConfirm"),
        "",
        async () => {
          const res = await delBeisenSecret();
          if (res.flag !== 0) {
            showMsg(res);
            this.beisenLoading = false;
            return;
          }
          this.beisen = res.data;
          showMsg({
            flag: 0,
            msg: this.$t("showMsg.success.operation")
          });
          this.beisenLoading = false;
          await this.getBeisenKey();
        },
        () => {
          this.beisenLoading = false;
        }
      );
    },
    // 获取北森密钥
    async getBeisenKey() {
      this.beisenLoading = true;
      const res = await getBeisenSecret();
      if (res.flag !== 0) {
        showMsg(res);
        this.beisenLoading = false;
        return;
      }
      this.beisen = res.data;
      this.beisenLoading = false;
    },
    // 北森复制密钥
    onBeisenCopy() {
      navigator.clipboard.writeText(this.beisen);
      showMsg({
        flag: 0,
        msg: this.$t("showMsg.success.copy")
      });
    },

    //飞书：获取密钥
    async getFeiShuSecret() {
      this.feishuLoading = true;
      const res = await getFeiShuSecret();
      if (res.flag !== 0) {
        showMsg(res);
        this.feishuLoading = false;
        return;
      }
      this.feiShuSecret = res.data;
      this.feishuLoading = false;
    },
    //飞书：查看密钥
    handleFeiShuSecretDialog(visible) {
      this.feiShuSecretDialogVisible = visible;
    },
    //飞书：创建密钥
    async handleFeiShuSecretCreation() {
      if (this.feishuLoading) return;
      confirmModal(
        this.$t("member.bind.feishu.createConfirm"),
        "",
        async () => {
          const res = await createFeiShuSecret();
          if (res.flag !== 0) {
            showMsg(res);
            this.feishuLoading = false;
            return;
          }
          this.feiShuSecret = res.data;
          showMsg({
            flag: 0,
            msg: this.$t("showMsg.success.operation")
          });
          this.feishuLoading = false;
          await this.getFeiShuSecret();
          this.handleFeiShuSecretDialog(true);
        },
        () => {
          this.feishuLoading = false;
        }
      );
    },
    //飞书：重置密钥
    async handleFeiShuSecretReplacement() {
      if (this.feishuLoading) return;

      confirmModal(
        this.$t("member.bind.feishu.resetConfirm"),
        "",
        async () => {
          const res = await reSetFeiShuSecret();
          if (res.flag !== 0) {
            showMsg(res);
            this.feishuLoading = false;
            return;
          }
          this.feiShuSecret = res.data;
          showMsg({
            flag: 0,
            msg: this.$t("showMsg.success.operation")
          });
          this.feishuLoading = false;
          await this.getFeiShuSecret();
          this.handleFeiShuSecretDialog(true);
        },
        () => {
          this.feishuLoading = false;
        }
      );
    },
    //飞书：复制密钥
    handleFeiShuSecretCopy() {
      navigator.clipboard.writeText(this.feiShuSecret);
      showMsg({
        flag: 0,
        msg: this.$t("showMsg.success.copy")
      });
    },
    //飞书：更新套餐
    async handleFeiShuPackageUpdate() {
      if (this.feishuLoading) return;

      const res = await updateFeiShuPackage();

      if (res.flag !== 0) {
        showMsg(res);
      } else {
        showMsg({
          flag: 0,
          msg: this.$t("showMsg.success.operation")
        });
      }

      this.feishuLoading = false;
    },
    // 获取moka绑定状态和绑定地址
    async getMokaBindUrl() {
      this.mokaLoading = true;
      const res = await getMokaBind();
      if (res.flag !== 0) {
        showMsg(res);
        this.mokaLoading = false;
        return;
      }
      this.mokaUrl = res.data;
      this.mokaLoading = false;
    },
    // 获取moka绑定状态和绑定地址
    async getMokaBindData() {
      this.mokaLoading = true;
      const res = await getMokaBindData();
      if (res.flag !== 0) {
        showMsg(res);
        this.mokaLoading = false;
        return;
      }
      this.mokaOpenid = res.data?.openId;
      this.mokaLoading = false;
    },
    // 获取moka绑定
    async getMokaBind() {
      await this.getMokaBindUrl();
      await this.getMokaBindData();
    },
    // 解绑moka
    onMokaRemove() {
      if (this.mokaLoading) return;
      confirmModal(
        this.$t("member.bind.beisen.removeConfirm"),
        "",
        async () => {
          const res = await delMokaBind();
          if (res.flag !== 0) {
            showMsg(res);
            this.mokaLoading = false;
            return;
          }
          this.beisen = res.data;
          showMsg({
            flag: 0,
            msg: this.$t("showMsg.success.operation")
          });
          this.mokaLoading = false;
          await this.getMokaBind();
        },
        () => {
          this.mokaLoading = false;
        }
      );
    },
    // moka跳转
    onMoka() {
      // 已绑定不跳转
      if (this.mokaOpenid) return;
      if (!this.mokaUrl) {
        showMsg({
          flag: 1,
          msg: this.$t("member.bind.moka.noUrl")
        });
        return;
      }
      // 未绑定点击跳转
      window.open(this.mokaUrl);
    },
    // 打开操作提示
    onTips(type = 0) {
      switch (type) {
        case 2:
          this.beisenTipsVisible = true;
          break;

        case 3:
          this.feiShuTipsVisible = true;
          break;

        default:
          break;
      }
    },
    onBeisenTipsClick() {
      const viewer = this.$refs.beisenTipsRef.$viewer;
      this.$nextTick(() => {
        setTimeout(() => {
          viewer.zoom(3);
          viewer.move(0, 1300);
        }, 900);
      });
    },
    onFeiShuHireTipsClick() {
      const viewer = this.$refs.feiShuHireTipsRef.$viewer;
      this.$nextTick(() => {
        setTimeout(() => {
          viewer.zoom(3);
          viewer.move(0, 1300);
        }, 900);
      });
    }
  },
  mounted() {
    this.getBeisenKey();
    this.getMokaBind();
    this.getFeiShuSecret();
  }
};
</script>

<style lang="less" scoped>
.bind-wrap {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-bottom: 20px;

  .bind-item {
    width: 256px;
    height: 117px;
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #ccd8f0;
    text-align: center;
    position: relative;

    /deep/.ant-spin-nested-loading {
      height: 100%;

      .ant-spin-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        padding-top: 42px;
        padding-bottom: 12px;
      }
    }

    .bind-icon {
      line-height: 1;
    }

    .bind-btn-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }

    .bind-badge {
      font-size: 14px;
      font-weight: 400;
      color: @primary-color;
      text-align: center;
      height: 20px;
      line-height: 18px;
      background: rgba(2, 122, 255, 0.08);
      border-radius: 20px;
      opacity: 1;
      border: 1px solid @primary-color;
      padding: 0 7px;
      position: absolute;
      z-index: 1;
      top: 11px;
      right: 18px;
    }

    .bind-tips {
      position: absolute;
      z-index: 1;
      top: 11px;
      right: 18px;
      font-size: 16px;
      cursor: pointer;
    }

    &.beisen {
      .bind-icon {
        font-size: 25px;
      }
    }

    &.moka {
      &.unbind {
        cursor: pointer;
      }

      .bind-icon {
        width: 125px;
        height: 25px;
      }
    }
  }
}
</style>

<style lang="less">
.member-bind-modal-content {
  padding: 0 30px;

  .secret-title {
    font-size: 16px;
  }

  .secret-value {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    margin-top: 5px;

    .anticon-copy {
      color: @primary-color;
      cursor: pointer;
    }
  }
}

.bind-icon-feishu {
  margin-top: -13px;
}

.bind-btn-danger {
  color: #f5222d;
}
</style>
